/* eslint-disable react/jsx-handler-names */
import { addDays, addHours, addMinutes, isToday, set, setHours, setMinutes, subDays } from 'date-fns';
import { useTranslation } from 'next-i18next';
import dynamic from 'next/dynamic';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { Field, useForm } from 'react-final-form';
import { Calendar } from '@indriver/mireska';
import { Radio } from '@indriver/nova';
import { toBeRequired } from 'common/lib';
import { CustomDateTimeInput, FormField } from 'common/ui';
import { useGetVariations } from 'entities/ab';
import * as Styled from './datetime-field.styles';
import { ceilTime } from './lib/ceil-time';
import { formatInitialDate } from './lib/format-initial-date';
import { getNearestAvailableTime } from './lib/get-nearest-available-time';
import { isTimePastOrWithinInterval } from './lib/is-time-past-or-within-interval';
import { loadAndRegisterLocale } from './lib/load-and-register-locale';
import 'react-datepicker/dist/react-datepicker.min.css';
interface IDatetimeFieldProps {
  name: string;
  label: string;
  required: boolean | undefined;
  date: Date | null;
  setDate: React.Dispatch<React.SetStateAction<Date | null>>;
  inline?: boolean;
  startDateForOrderExtension?: Date;
  isInEditPage?: boolean;
}
interface IData {
  name?: string;
  value: string;
}
type DateOption = 'urgent' | 'not_urgent' | 'exact_time';
const DynamicDatePicker = dynamic(async () => import('react-datepicker'), {
  ssr: false
});
export const DatetimeField = React.memo(({
  name,
  label,
  required,
  date,
  setDate,
  inline,
  startDateForOrderExtension,
  isInEditPage = true
}: IDatetimeFieldProps) => {
  const {
    t,
    i18n
  } = useTranslation();
  const {
    isEnabled: areSubtitlesHidden
  } = useGetVariations('masters_when_hide_subtitles');
  const {
    asapHours = 3
  } = useGetVariations('masters_asap_term');
  const datePlaceholder = t('service_id_page_new_choose_date_placeholder');
  const form = useForm();
  const [option, setOption] = useState<DateOption>();
  const exactDateTimeFieldRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    const value = form.getFieldState('date_option')?.value;
    if (value) {
      setOption(value);
    }
  }, [form]);
  const handleDateOptionChange = (e: ChangeEvent<HTMLInputElement>, data: IData) => {
    setOption((data.value as DateOption));
    form.change('date_option', data.value);
    if (data.value === 'urgent') {
      const time = addHours(ceilTime(new Date()), asapHours);
      form.change(`${name}.time`, time);
      setDate(time);
      form.change(`${name}.is_time_detailed`, true);
      form.change(`${name}.time_alias`, 'asap');
    }
    if (data.value === 'not_urgent') {
      const time = set(addDays(new Date(), 7), {
        hours: 12,
        minutes: 0,
        seconds: 0
      });
      form.change(`${name}.time`, time);
      setDate(time);
      form.change(`${name}.is_time_detailed`, false);
      form.change(`${name}.time_alias`, 'not_urgently');
    }
    if (data.value === 'exact_time') {
      form.change(`${name}.time_alias`, 'exact_time');
      if (exactDateTimeFieldRef.current) {
        exactDateTimeFieldRef.current.scrollIntoView({
          behavior: 'smooth'
        });
      }
    }
  };
  React.useEffect(() => {
    void loadAndRegisterLocale(i18n.resolvedLanguage);
  }, [i18n.resolvedLanguage]);
  return <div>
                <Styled.Label required={required} htmlFor={name}>
                    <Styled.LabelText>{label}</Styled.LabelText>
                    {required && <Styled.LabelRequired>*</Styled.LabelRequired>}
                </Styled.Label>
                {!isInEditPage && <Styled.FormFieldContainer role='group'>
                        <Styled.RadioWrapper>
                            <Field name='date_option'>
                                {props => <>
                                        <Radio size='m' name={props.input.name} value='urgent' checked={props.input.value === 'urgent'} onChange={handleDateOptionChange}>
                                            {t('masters_customer_datefield_ordertime_asap')}
                                        </Radio>
                                        {!areSubtitlesHidden && <Styled.RadioBottomLabel size='s' color='var(--text-and-icon-secondary)'>
                                                {t('masters_customer_datefield_ordertime_asap_hint', {
                count: asapHours
              })}
                                            </Styled.RadioBottomLabel>}
                                    </>}
                            </Field>
                        </Styled.RadioWrapper>
                        <Styled.RadioWrapper>
                            <Field name='date_option'>
                                {props => <>
                                        <Radio size='m' name={props.input.name} value='not_urgent' checked={props.input.value === 'not_urgent'} onChange={handleDateOptionChange}>
                                            {areSubtitlesHidden ? t('masters_customer_datefield_ordertime_during_week') : t('masters_customer_datefield_ordertime_week')}
                                        </Radio>
                                        {!areSubtitlesHidden && <Styled.RadioBottomLabel size='s' color='var(--text-and-icon-secondary)'>
                                                {t('masters_customer_datefield_ordertime_week_hint')}
                                            </Styled.RadioBottomLabel>}
                                    </>}
                            </Field>
                        </Styled.RadioWrapper>
                        <Styled.RadioWrapper>
                            <Field name='date_option'>
                                {props => <>
                                        <Radio size='m' name={props.input.name} value='exact_time' checked={props.input.value === 'exact_time'} onChange={handleDateOptionChange}>
                                            {areSubtitlesHidden ? t('masters_customer_datefield_ordertime_certain_time') : t('masters_customer_datefield_ordertime_time')}
                                        </Radio>
                                        {!areSubtitlesHidden && <Styled.RadioBottomLabel size='s' color='var(--text-and-icon-secondary)'>
                                                {t('masters_customer_datefield_ordertime_time_hint')}
                                            </Styled.RadioBottomLabel>}
                                    </>}
                            </Field>
                        </Styled.RadioWrapper>
                    </Styled.FormFieldContainer>}
                <Styled.Row isVisible={option === 'exact_time' || isInEditPage} ref={exactDateTimeFieldRef}>
                    <Field name={`${name}.time`} format={formatInitialDate} validate={required ? (value: Date) => toBeRequired(value, datePlaceholder) : undefined}>
                        {props => {
          const isError = required && !!props.meta.error && props.meta.touched;
          const handleChange = (d: Date | null) => {
            props.input.onChange(d);
            setDate(d);
            if (d && !isTimePastOrWithinInterval(d)) {
              form.change(`${name}.is_time_detailed`, true);
            }
            form.change(`${name}.time_alias`, 'exact_time');
          };
          const handleCalendarClose = () => {
            if (date && isTimePastOrWithinInterval(date)) {
              const nearestAvailableTime = getNearestAvailableTime(date);
              setDate(nearestAvailableTime);
              props.input.onChange(nearestAvailableTime);
              form.change(`${name}.is_time_detailed`, true);
              form.change(`${name}.time_alias`, 'exact_time');
            }
          };
          return <FormField invalid={isError} hint={isError ? datePlaceholder : undefined}>
                                    <DynamicDatePicker selected={props.input.value} includeDateIntervals={[{
              start: subDays(startDateForOrderExtension ?? new Date(), 1),
              end: addDays(new Date(), 90)
            }]} locale={i18n.resolvedLanguage} dateFormat='EEEEEE dd MMMM HH:mm' customInput={<CustomDateTimeInput icon={<Calendar size={24} />} placeholderText={datePlaceholder} invalid={isError} />} withPortal={!inline} inline={inline} showTimeSelect timeIntervals={15} timeCaption={t('masters_wizard_form_time_picker_caption')} minTime={date && isToday(date) ? addMinutes(ceilTime(new Date()), 30) : setHours(setMinutes(new Date(), 0), 0)} maxTime={setHours(setMinutes(new Date(), 45), 23)} onChange={handleChange} onCalendarClose={handleCalendarClose} />
                                </FormField>;
        }}
                    </Field>
                    <Field name={`${name}.is_time_detailed`} initialValue>
                        {props => <input type='checkbox' hidden checked={props.input.value} onChange={props.input.onChange} />}
                    </Field>
                    <Field name={`${name}.time_alias`} initialValue='exact_time'>
                        {props => <input type='input' hidden value={props.input.value} onChange={props.input.onChange} />}
                    </Field>
                </Styled.Row>
            </div>;
});