import { Children, cloneElement, ReactElement, useCallback, useRef } from 'react';
import { useBoolean } from 'usehooks-ts';
import { IDropListProps } from '@indriver/nova';
import { useBreakpoints } from 'common/lib';
import { DropList, MobileDrawer } from 'common/ui';
import { ICityInfo } from 'entities/city';
import { Cities } from 'features/cities';
import * as Styled from './cities-drop-list.styles';

export interface ICitiesDropListProps extends Pick<IDropListProps, 'orientation' | 'offset' | 'width'> {
    visibility: ReturnType<typeof useBoolean>;
    children: ReactElement;
    onSelect?: (cityInfo: ICityInfo) => void;
    onClose?: () => void;
}

export const CitiesDropList = ({ visibility, children, onSelect, onClose, ...props }: ICitiesDropListProps) => {
    const handleCityClick = useCallback(
        async (cityInfo: ICityInfo) => {
            visibility.setFalse();
            onSelect?.(cityInfo);
        },
        [onSelect, visibility],
    );

    const handleClose = useCallback(async () => {
        visibility.setFalse();
        onClose?.();
    }, [onClose, visibility]);

    const anchorRef = useRef<HTMLElement>(null);
    const media = useBreakpoints();
    return (
        <>
            {cloneElement(Children.only(children), { ref: anchorRef })}
            {media.isGtS ? (
                <DropList
                    anchorRef={anchorRef}
                    opened={visibility.value}
                    {...props}
                    withoutPadding
                    onClickOutside={visibility.setFalse}>
                    <Styled.DesktopWrapper>
                        <Cities onCityClick={handleCityClick} onClose={handleClose} />
                    </Styled.DesktopWrapper>
                </DropList>
            ) : (
                <MobileDrawer isOpen={visibility.value}>
                    <Styled.MobileWrapper>
                        <Cities onCityClick={handleCityClick} onClose={handleClose} />
                    </Styled.MobileWrapper>
                </MobileDrawer>
            )}
        </>
    );
};
