import { ILocation } from 'common/api';
import { Distance } from 'common/ui';
import { Experience, ITasker, OrdersCount, Portfolio } from 'entities/tasker';

export const renderTaskerDetails = (
    tasker: ITasker,
    bidLocation: ILocation | undefined,
    orderLocation: ILocation | undefined,
    countryIso: string,
) => (
    <>
        <Distance from={bidLocation} to={orderLocation} countryIso={countryIso} />
        {tasker.completedOrders ? <OrdersCount tasker={tasker} /> : <Portfolio tasker={tasker} />}
        <Experience tasker={tasker} />
    </>
);
