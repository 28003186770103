import { NextPageContext } from 'next';
import { getDomainI18nConfig } from 'entities/domain';

export const getAlternateLinks = ({ req, asPath = '' }: NextPageContext) => {
    if (req === undefined) {
        return [];
    }

    const { domain, locales, defaultLocale, countryIso } = getDomainI18nConfig(req);
    const url = new URL(asPath, `https://${domain}`);

    return locales.map((locale) => {
        const prefix = locale === defaultLocale ? '' : locale.toLowerCase();
        url.pathname = `${prefix}${asPath}`.replace(/\/$/, '');

        const [lang] = locale.split('-');

        return {
            hrefLang: countryIso ? `${lang}-${countryIso.toUpperCase()}` : lang,
            href: `${url.origin}${url.pathname}`,
        };
    });
};
