import { useBreakpoints } from 'common/lib';
import { LogoBr } from './components/logo-br';
import { LogoDefault } from './components/logo-default';
import { LogoKz } from './components/logo-kz';
import { LogoLatAm } from './components/logo-lat-am';
import { LogoRu } from './components/logo-ru';

interface IProps {
    countryIso?: string;
    locale: string;
}

const getComponent = (countryIso = 'default', locale: string) => {
    if (countryIso === 'br') {
        return LogoBr;
    }
    if (countryIso === 'kz' && locale === 'kk') {
        return LogoKz;
    }
    if (countryIso === 'kz' && locale === 'ru') {
        return LogoRu;
    }
    if (['cl', 'co', 'ec', 'mx', 'pe'].includes(countryIso)) {
        return LogoLatAm;
    }

    return LogoDefault;
};

export const Logo = ({ countryIso, locale }: IProps) => {
    const media = useBreakpoints();
    const Component = getComponent(countryIso, locale);
    return <Component height={media.isGtS ? 40 : 32} isMobile={!media.isGtS} />;
};
