import { useTranslation } from 'next-i18next';
import React, { ReactNode } from 'react';
import { ChatOutline, DocVerified, DoneOutline } from '@indriver/mireska';
import { Heading, Tag, Text } from '@indriver/nova';
import { variables } from '@indriver/yrel';
import { ITaskerInfo, IWizardReviewUser } from 'common/api';
import { anonymizeName } from 'common/lib';
import { AvatarWithBadge, ClampedText } from 'common/ui';
import { getDescription, hasDescription } from 'entities/tasker';
import * as Styled from './tasker-card.styles';

interface ITaskerCardProps {
    taskerUser: IWizardReviewUser;
    taskerInfo: ITaskerInfo;
    footer?: ReactNode;
}

export const TaskerCard = ({ taskerUser, taskerInfo, footer }: ITaskerCardProps) => {
    const { t } = useTranslation();
    return (
        <Styled.Container>
            <Styled.Header>
                <AvatarWithBadge
                    avatarSize='xxxl'
                    badgeSize='m'
                    content={taskerUser.first_name}
                    iconSize={16}
                    image={taskerUser.avatar}
                    rating={taskerInfo.rating}
                />
                <Heading type='h2' size='l' style={{ marginTop: variables['spacers-2xs'] }}>
                    {anonymizeName(taskerUser)}
                </Heading>
                <Styled.Tags>
                    <Tag
                        prefix={<ChatOutline size={16} />}
                        design='secondary'
                        label={t('common_reviews', { count: taskerInfo.count_review })}
                    />
                    <Tag
                        prefix={<DoneOutline size={16} />}
                        design='secondary'
                        label={t('common_orders', { count: taskerInfo.completed_orders })}
                    />
                    <Tag
                        prefix={<DocVerified size={16} />}
                        design='secondary'
                        label={t('top_masters__passport_verified')}
                    />
                </Styled.Tags>
            </Styled.Header>

            {hasDescription(taskerInfo) && (
                <Text size='m' color={variables['text-and-icon-secondary']}>
                    <ClampedText lines={3} expanded>
                        {getDescription(taskerInfo)}
                    </ClampedText>
                </Text>
            )}
            {footer && <Styled.Footer>{footer}</Styled.Footer>}
        </Styled.Container>
    );
};
