import { useQuery } from '@tanstack/react-query';
import { getCategoryOffers, getOffers } from 'common/api/offer';

interface IUseGetOfferParams {
    orderId: string;
    enabled: boolean;
}

export const useGetOffers = ({ orderId, enabled = false }: IUseGetOfferParams) =>
    useQuery({
        queryKey: ['getOffers', { orderId }],
        queryFn: async () => getOffers(orderId),
        enabled,
    });

interface IUseGetCategoryOfferParams {
    catalogId?: number;
    enabled?: boolean;
}

export const useGetServiceOffers = ({ catalogId, enabled = true }: IUseGetCategoryOfferParams) =>
    useQuery({
        queryKey: ['getCategoryOffers', { catalogId }],
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        queryFn: async () => getCategoryOffers(catalogId!),
        enabled: catalogId !== undefined && enabled,
    });
