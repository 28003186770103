import { IProps } from './props';

export const LogoBr = ({ height, isMobile }: IProps) => {
    return (
        <svg
            width={isMobile ? 112 : 140}
            height={height}
            viewBox='0 0 140 40'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'>
            <g clipPath='url(#clip0_5511_20449)'>
                <path
                    d='M-1.88379e-05 19.7122C-1.88379e-05 14.2377 -1.88404e-05 11.5019 0.763731 9.29895C2.15159 5.29781 5.29779 2.15161 9.30177 0.76375C11.5047 0 14.2405 0 19.7121 0C25.1838 0 27.9225 0 30.1254 0.76375C34.1294 2.15161 37.2756 5.29781 38.6634 9.3018C39.4272 11.5047 39.4272 14.2405 39.4272 19.715C39.4272 25.1895 39.4272 27.9253 38.6634 30.1282C37.2756 34.1322 34.1294 37.2784 30.1254 38.6663C27.9225 39.43 25.1866 39.43 19.7121 39.43C14.2377 39.43 11.5018 39.43 9.29893 38.6663C5.29494 37.2784 2.14874 34.1322 0.760882 30.1282C-0.00286865 27.9253 -0.00286865 25.1895 -0.00286865 19.715'
                    fill='#A7E92F'
                />
                <path
                    d='M14.0638 16.9821H10.9147C10.5024 16.9821 10.1681 17.3163 10.1681 17.7287V29.3958C10.1681 29.8082 10.5024 30.1425 10.9147 30.1425H14.0638C14.4762 30.1425 14.8104 29.8082 14.8104 29.3958V17.7287C14.8104 17.3163 14.4762 16.9821 14.0638 16.9821Z'
                    fill='black'
                />
                <path
                    d='M14.0638 9.27045H10.9147C10.5024 9.27045 10.1681 9.60473 10.1681 10.0171V13.0408C10.1681 13.4531 10.5024 13.7874 10.9147 13.7874H14.0638C14.4762 13.7874 14.8104 13.4531 14.8104 13.0408V10.0171C14.8104 9.60473 14.4762 9.27045 14.0638 9.27045Z'
                    fill='black'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M21.6272 25.6341H19.1165C18.6691 25.6341 18.3699 25.9333 18.3699 26.3807V29.393C18.3699 29.8404 18.6691 30.1368 19.1165 30.1396L21.6272 30.1567C35.939 30.1567 35.939 9.27045 21.6272 9.27045L19.1165 9.28755C18.6691 9.2904 18.3699 9.58678 18.3699 10.0342V13.0465C18.3699 13.4939 18.6691 13.7931 19.1165 13.7931H21.6272C29.7235 13.7931 29.7235 25.6341 21.6272 25.6341Z'
                    fill='black'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M93.2744 5.27501C95.6882 5.27501 97.3069 7.17013 96.851 9.49843H91.3907C91.5104 10.4845 92.3283 11.0829 93.2431 11.0801C94.0781 11.0801 94.7677 10.7011 95.1382 9.93445L96.6885 10.7267C96.0673 11.9436 94.7592 12.7444 93.2744 12.7444C91.043 12.733 89.4927 11.0715 89.4927 9.00826C89.4927 6.945 91.0573 5.2864 93.2744 5.27216M95.0955 8.24451C94.9159 7.48931 94.098 6.9336 93.2687 6.9336C92.4394 6.9336 91.6472 7.46366 91.442 8.24451H95.0955Z'
                    fill='black'
                />
                <path
                    d='M58.2929 6.2069L58.2388 5.47735H56.5346V12.542H58.3699V8.90853C58.3699 6.74267 61.3622 6.57738 61.3622 8.43261V12.542H63.1975V8.26732C63.1975 5.10117 59.7064 4.7136 58.2872 6.2069'
                    fill='black'
                />
                <path
                    d='M67.9111 10.7951H66.4976V5.29782H67.9111C69.5298 5.29782 70.6726 6.4292 70.6726 8.04789C70.6726 9.66659 69.5298 10.798 67.9111 10.798V10.7951ZM67.9111 3.55088H64.6623V12.542H67.9111C70.5301 12.542 72.5078 10.6156 72.5078 8.04504C72.5078 5.47451 70.5301 3.54803 67.9111 3.54803V3.55088Z'
                    fill='black'
                />
                <path
                    d='M75.5115 6.24394L75.4574 5.47734H73.7532V12.542H75.5885V9.47278C75.5885 7.74009 76.9079 6.99344 78.3642 7.31547V5.42889C77.4779 5.20661 76.1727 5.3434 75.5087 6.24679'
                    fill='black'
                />
                <path
                    d='M87.5663 5.47736L85.8307 10.55L84.1066 5.47736H82.1545L84.7364 12.542H86.9336L89.5184 5.47736H87.5663Z'
                    fill='black'
                />
                <path d='M55.1154 5.47736H53.2773V12.542H55.1154V5.47736Z' fill='black' />
                <path d='M55.1154 2.74152H53.2773V4.57965H55.1154V2.74152Z' fill='black' />
                <path d='M81.2026 5.47736H79.3673V12.542H81.2026V5.47736Z' fill='black' />
                <path d='M81.2054 2.74152H79.3673V4.57965H81.2054V2.74152Z' fill='black' />
                <path
                    d='M60.5243 25.8849C60.3533 24.7079 59.715 24.2918 58.2701 24.2918C57.0703 24.2918 56.3835 24.5853 56.3835 25.2978C56.3835 26.0102 57.0447 26.3038 58.3442 26.6714C59.7178 27.0647 61.0145 27.3326 61.9464 27.7002C63.2202 28.216 63.9327 29.0481 63.9327 30.6155C63.9327 33.1148 62.0946 34.611 58.6634 34.611C54.9615 34.611 52.9039 32.8698 52.8555 30.4189H56.1385C56.1385 31.5474 57.0931 32.2086 58.6377 32.2086C59.7663 32.2086 60.7694 31.8666 60.7694 30.9575C60.7694 30.0997 59.8632 29.829 58.8344 29.5839C56.7996 29.0937 55.6739 28.7518 54.8161 28.2103C53.6876 27.5007 53.2972 26.5688 53.2972 25.4887C53.2972 23.454 54.6936 21.9094 58.3699 21.9094C61.8495 21.9094 63.4169 23.283 63.5907 25.8792H60.5272L60.5243 25.8849Z'
                    fill='black'
                />
                <path
                    d='M68.1448 29.0938C68.2416 30.9062 69.2733 32.0832 70.8407 32.0832C71.8695 32.0832 72.7757 31.5674 72.998 30.7353H76.281C75.5457 33.2117 73.5594 34.6082 70.986 34.6082C66.9678 34.6082 64.9558 32.4024 64.9558 28.0393C64.9558 24.3146 67.0875 21.9122 70.7894 21.9122C74.4913 21.9122 76.452 24.3146 76.452 29.0938H68.1448ZM73.169 27.0846C73.1205 25.295 71.9436 24.4372 70.6925 24.4372C69.4414 24.4372 68.3642 25.4175 68.2417 27.0846H73.169Z'
                    fill='black'
                />
                <path
                    d='M77.6545 34.289V22.2314H80.644V23.6763C81.6984 22.0576 82.9239 21.9122 84.2234 21.9122H84.6394V25.1724C84.3459 25.124 84.0524 25.0983 83.756 25.0983C81.7953 25.0983 80.8406 26.0787 80.8406 28.0137V34.289H77.6545Z'
                    fill='black'
                />
                <path
                    d='M89.8603 34.2889L85.5457 22.2314H89.0994L91.6244 30.1966L94.1721 22.2314H97.5292L93.1918 34.2889H89.8575H89.8603Z'
                    fill='black'
                />
                <path
                    d='M98.4867 20.7865V17.8199H101.673V20.7865H98.4867ZM98.4867 34.289V22.2314H101.673V34.289H98.4867Z'
                    fill='black'
                />
                <path
                    d='M114.001 29.9288C113.659 32.7216 111.379 34.611 108.461 34.611C105.178 34.611 102.921 32.4309 102.921 28.1163C102.921 23.8017 105.175 21.9151 108.558 21.9151C111.696 21.9151 113.827 23.7048 114.024 26.546H110.812C110.641 25.295 109.735 24.5597 108.532 24.5597C107.184 24.5597 106.081 25.4175 106.081 28.0394C106.081 30.6612 107.184 31.935 108.435 31.935C109.686 31.935 110.667 31.2254 110.789 29.9259H114.001V29.9288ZM107.335 38.9484C108.341 38.8743 108.88 38.3101 108.903 37.3554H107.335V35.3463H110.154V36.6686C110.154 38.4326 109.515 39.8062 107.335 40.0029V38.9484Z'
                    fill='black'
                />
                <path
                    d='M115.104 28.2616C115.104 24.4372 117.481 21.9151 121.305 21.9151C125.13 21.9151 127.432 24.4144 127.432 28.2616C127.432 32.1089 125.055 34.6082 121.305 34.6082C117.555 34.6082 115.104 32.0091 115.104 28.2616ZM124.221 28.2616C124.221 25.8108 123.24 24.611 121.305 24.611C119.37 24.611 118.39 25.8108 118.39 28.2616C118.39 30.7125 119.37 31.9379 121.305 31.9379C123.24 31.9379 124.221 30.7125 124.221 28.2616Z'
                    fill='black'
                />
                <path
                    d='M135.862 25.8849C135.691 24.7079 135.053 24.2918 133.608 24.2918C132.408 24.2918 131.721 24.5853 131.721 25.2978C131.721 26.0102 132.382 26.3038 133.682 26.6714C135.056 27.0647 136.352 27.3326 137.284 27.7002C138.558 28.216 139.27 29.0481 139.27 30.6155C139.27 33.1148 137.432 34.611 134.001 34.611C130.299 34.611 128.242 32.8698 128.193 30.4189H131.476C131.476 31.5474 132.431 32.2086 133.975 32.2086C135.104 32.2086 136.107 31.8666 136.107 30.9575C136.107 30.0997 135.201 29.829 134.172 29.5839C132.137 29.0937 131.012 28.7518 130.154 28.2103C129.025 27.5007 128.635 26.5688 128.635 25.4887C128.635 23.454 130.031 21.9094 133.708 21.9094C137.187 21.9094 138.755 23.283 138.928 25.8792H135.865L135.862 25.8849Z'
                    fill='black'
                />
            </g>
            <defs>
                <clipPath id='clip0_5511_20449'>
                    <rect width='139.268' height='40' fill='white' />
                </clipPath>
            </defs>
        </svg>
    );
};
