import { useTranslation } from 'next-i18next';
import { ChevronRightSmall } from '@indriver/mireska';
import { RoundIcon, Text } from '@indriver/nova';
import { variables } from '@indriver/yrel';
import { MiniCell, Portraits } from 'common/ui';

interface IProps {
    taskerCount: number;
    onClick?: () => void;
}

export const PickTaskerCta = ({ taskerCount, onClick }: IProps) => {
    const { t } = useTranslation();

    return (
        <MiniCell
            prefix={<Portraits />}
            content={
                <>
                    <Text size='s' weight='bold' style={{ marginBottom: variables['spacers-3xs'] }}>
                        {t('common_specialists_count', { count: taskerCount })}
                    </Text>
                    <Text size='s' color='rgba(0,0,0,.5)'>
                        {t('features.mixed_model.pick_tasker_cta.subtitle')}
                    </Text>
                </>
            }
            suffix={<RoundIcon design='neutral-inverse' size='s' content={<ChevronRightSmall size={24} />} />}
            onClick={onClick}
        />
    );
};
