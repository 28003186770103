import useEmblaCarousel from 'embla-carousel-react';
import { i18n, useTranslation } from 'next-i18next';
import React from 'react';
import { useBreakpoints } from 'common/lib';
import {
    StyledHeading,
    StyledImage,
    StyledImageWrapper,
    StyledInstructionSection,
    StyledDesktopSlide,
    StyledSubHeading,
    StyledSubText,
    StyledDesktopCarouselControls,
    StyledDotsWrapper,
    StyledDesktopDotButton,
    StyledDesktopDotButtonHeading,
    StyledDesktopDotButtonText,
} from './instruction-section.styles';

const INSTRUCTION_TEXT = () => {
    return [
        {
            id: '1',
            heading: i18n?.t('main_page_instruction_section_first_slide_heading') as string,
            text: i18n?.t('main_page_instruction_section_first_slide_description') as string,
            image: {
                url: `/images/main-page/instructions/${i18n?.resolvedLanguage}-slide-1.png`,
                width: '456',
                height: '480',
            },
        },
        {
            id: '2',
            heading: i18n?.t('main_page_instruction_section_second_slide_heading') as string,
            text: i18n?.t('main_page_instruction_section_second_slide_description') as string,
            image: {
                url: `/images/main-page/instructions/${i18n?.resolvedLanguage}-slide-2.png`,
                width: '498',
                height: '433',
            },
        },
        {
            id: '3',
            heading: i18n?.t('main_page_instruction_section_third_slide_heading') as string,
            text: i18n?.t('main_page_instruction_section_third_slide_description') as string,
            image: {
                url: `/images/main-page/instructions/${i18n?.resolvedLanguage}-slide-3.png`,
                width: '520',
                height: '229',
            },
        },
    ];
};

const DotButton = ({ selected, index, onClick }: { selected: boolean; index: number; onClick: () => void }) => (
    <button
        className={`embla__dot ${selected ? 'is-selected' : ''}`}
        type='button'
        aria-label={`Перейти на слайд ${index + 1}`}
        onClick={onClick}
    />
);

const DesktopDotButton = ({
    selected,
    heading,
    text,
    onClick,
}: {
    selected: boolean;
    heading: string;
    text: string;
    onClick: () => void;
}) => (
    <StyledDesktopDotButton type='button' selected={selected} onClick={onClick}>
        <StyledDesktopDotButtonHeading>{heading}</StyledDesktopDotButtonHeading>
        <StyledDesktopDotButtonText>{text}</StyledDesktopDotButtonText>
    </StyledDesktopDotButton>
);

const Mobile = () => {
    const { t } = useTranslation();
    const [viewportRef, embla] = useEmblaCarousel({ loop: true });
    const [selectedIndex, setSelectedIndex] = React.useState<number>(0);
    const [scrollSnaps, setScrollSnaps] = React.useState<number[]>([]);

    const scrollTo = React.useCallback((index) => embla?.scrollTo(index), [embla]);

    const onSelect = React.useCallback(() => {
        if (!embla) {
            return;
        }
        setSelectedIndex(embla.selectedScrollSnap());
    }, [embla, setSelectedIndex]);

    React.useEffect(() => {
        if (!embla) {
            return;
        }
        onSelect();
        setScrollSnaps(embla.scrollSnapList());
        embla.on('select', onSelect);
    }, [embla, setScrollSnaps, onSelect]);

    return (
        <StyledInstructionSection>
            <StyledHeading>{t('main_page_instruction_section_heading')}</StyledHeading>
            <div className='embla'>
                <div className='embla__viewport' ref={viewportRef}>
                    <div className='embla__container'>
                        {INSTRUCTION_TEXT().map((slide) => (
                            <div key={slide.id} className='embla__slide'>
                                <StyledDesktopSlide>
                                    <div>
                                        <StyledSubHeading>{slide.heading}</StyledSubHeading>
                                        <StyledSubText>{slide.text}</StyledSubText>
                                    </div>
                                    <StyledImageWrapper>
                                        <StyledImage
                                            src={slide.image.url}
                                            alt=''
                                            width={slide.image.width}
                                            height={slide.image.height}
                                        />
                                    </StyledImageWrapper>
                                </StyledDesktopSlide>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className='embla__dots'>
                {scrollSnaps.map((_, index) => (
                    <DotButton
                        // eslint-disable-next-line react/no-array-index-key
                        key={index}
                        selected={index === selectedIndex}
                        index={index}
                        onClick={() => scrollTo(index)}
                    />
                ))}
            </div>
        </StyledInstructionSection>
    );
};

const Desktop = () => {
    const { t } = useTranslation();
    const [viewportRef, embla] = useEmblaCarousel({ loop: true, axis: 'y' });
    const [selectedIndex, setSelectedIndex] = React.useState<number>(0);
    const [scrollSnaps, setScrollSnaps] = React.useState<number[]>([]);

    const scrollTo = React.useCallback((index) => embla?.scrollTo(index), [embla]);

    const onSelect = React.useCallback(() => {
        if (!embla) {
            return;
        }
        setSelectedIndex(embla.selectedScrollSnap());
    }, [embla, setSelectedIndex]);

    React.useEffect(() => {
        if (!embla) {
            return;
        }
        onSelect();
        setScrollSnaps(embla.scrollSnapList());
        embla.on('select', onSelect);
    }, [embla, setScrollSnaps, onSelect]);

    return (
        <StyledInstructionSection>
            <div className='embla'>
                <div className='embla__viewport' ref={viewportRef}>
                    <div className='embla__container'>
                        <div className='embla__slide'>
                            <StyledImageWrapper>
                                <StyledImage
                                    src={`/images/main-page/instructions/${i18n?.resolvedLanguage}-slide-1.png`}
                                    alt=''
                                    width='456'
                                    height='480'
                                    loading='eager'
                                />
                            </StyledImageWrapper>
                        </div>
                        <div className='embla__slide'>
                            <StyledImageWrapper>
                                <StyledImage
                                    src={`/images/main-page/instructions/${i18n?.resolvedLanguage}-slide-2.png`}
                                    alt=''
                                    width='498'
                                    height='433'
                                    loading='eager'
                                />
                            </StyledImageWrapper>
                        </div>
                        <div className='embla__slide'>
                            <StyledImageWrapper>
                                <StyledImage
                                    src={`/images/main-page/instructions/${i18n?.resolvedLanguage}-slide-3.png`}
                                    alt=''
                                    width='520'
                                    height='229'
                                    loading='eager'
                                />
                            </StyledImageWrapper>
                        </div>
                    </div>
                </div>
            </div>
            <StyledDesktopCarouselControls>
                <StyledHeading>{t('main_page_instruction_section_heading')}</StyledHeading>
                <StyledDotsWrapper>
                    {scrollSnaps.map((_, index) => (
                        <DesktopDotButton
                            // eslint-disable-next-line react/no-array-index-key
                            key={index}
                            heading={INSTRUCTION_TEXT()[index].heading}
                            text={INSTRUCTION_TEXT()[index].text}
                            selected={index === selectedIndex}
                            onClick={() => scrollTo(index)}
                        />
                    ))}
                </StyledDotsWrapper>
            </StyledDesktopCarouselControls>
        </StyledInstructionSection>
    );
};

export const InstructionSection = () => {
    const media = useBreakpoints();
    return media.isGtL ? <Desktop /> : <Mobile />;
};
