import { superMastersApi } from './base';
import { localPublicApi } from './local-public';
import { IAveragePriceResponse, ICatalogServiceResponse, ICatalogSubServicesResponse } from './models';

interface IGetServiceByCatalogIdParams {
    locale: string;
    catalogId: number;
    subServiceSlug?: string;
}

export const getServiceByCatalogId = async ({ locale, catalogId, subServiceSlug }: IGetServiceByCatalogIdParams) => {
    const response = await superMastersApi.get<ICatalogServiceResponse>(`public/catalog/service/${catalogId}`, {
        headers: { 'Accept-Language': locale },
        params: { subServiceSlug },
    });
    return subServiceSlug ? { ...response.data, sub_service_slug: subServiceSlug } : response.data;
};

export interface IGetSubServicesByCatalogIdParams {
    locale: string;
    catalogId: number;
}

export const getSubServicesByCatalogId = async ({ locale, catalogId }: IGetSubServicesByCatalogIdParams) => {
    const response = await superMastersApi.get<ICatalogSubServicesResponse>(`public/catalog/sub-service/${catalogId}`, {
        headers: { 'Accept-Language': locale },
    });
    return response.data.collection;
};

export const getAveragePriceByCatalogId = async (catalogId: number) => {
    const response = await localPublicApi.get<IAveragePriceResponse>('v2/get-average-price', { params: { catalogId } });
    return response.data;
};
