import { i18n } from 'next-i18next';

export const formatPrice = (price: number, currency: string) => {
    return Intl.NumberFormat(i18n?.resolvedLanguage, {
        style: 'currency',
        currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
    })
        .formatToParts(price)
        .map(({ type, value }) => {
            if (type === 'currency' && value === 'KZT') {
                return '₸';
            }

            return value;
        });
};
