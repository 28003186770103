import { useTranslation } from 'next-i18next';
import { useCallback } from 'react';
import { Loader } from '@indriver/nova';
import { removeAccents, useBreakpoints } from 'common/lib';
import { AutocompleteFlat, CloseButton } from 'common/ui';
import { CitiesList, ICityInfo, useOrderedCitiesList, usePageCity } from 'entities/city';
import { useDomainInfo } from 'entities/domain';
import * as Styled from './cities.styles';

interface IProps {
    onClose: () => void;
    onCityClick: (cityInfo: ICityInfo) => void;
}

export const Cities = ({ onClose, onCityClick }: IProps) => {
    const { t } = useTranslation();
    const media = useBreakpoints();
    const { countryIso: domainCountryIso } = useDomainInfo();
    const { countryIso: pageCountryIso } = usePageCity();
    const { data: cities = [], isLoading, isSuccess } = useOrderedCitiesList({ domainCountryIso, pageCountryIso });

    const citiesFilter = useCallback((search: string, city: ICityInfo) => {
        const sanitizedSearch = removeAccents(search.toLowerCase());
        return (
            removeAccents(city.name.toLowerCase()).includes(sanitizedSearch) ||
            removeAccents(city.citySlug.toLowerCase()).includes(sanitizedSearch) ||
            removeAccents(city.countryName.toLowerCase()).includes(sanitizedSearch)
        );
    }, []);

    return (
        <>
            {!media.isGtS && (
                <Styled.Header>
                    <Styled.Heading>{t('city_switcher_city_question_text')}</Styled.Heading>
                    <CloseButton onClick={onClose} />
                </Styled.Header>
            )}
            {isLoading && (
                <Styled.LoaderWrapper>
                    <Loader />
                </Styled.LoaderWrapper>
            )}
            {isSuccess && (
                <AutocompleteFlat<ICityInfo>
                    placeholder={t('city_switcher_search_input_placeholder')}
                    items={cities}
                    filter={citiesFilter}>
                    {(filteredCities) =>
                        filteredCities.length > 0 ? (
                            <CitiesList cities={filteredCities} onCityClick={onCityClick} />
                        ) : (
                            <Styled.EmptyPlaceholder>{t('city_switcher_search_no_results')}</Styled.EmptyPlaceholder>
                        )
                    }
                </AutocompleteFlat>
            )}
        </>
    );
};
