import { IProps } from './props';

export const LogoRu = ({ height, isMobile }: IProps) => {
    return (
        <svg
            width={isMobile ? 112 : 140}
            height={height}
            viewBox='0 0 141 40'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'>
            <g clipPath='url(#clip0_5511_20345)'>
                <path
                    d='M-8.00756e-05 19.7108C-8.00756e-05 14.2367 -8.00784e-05 11.501 0.763616 9.29828C2.15138 5.29743 5.29735 2.15146 9.30105 0.763696C11.5038 0 14.2394 0 19.7107 0C25.1819 0 27.9204 0 30.1232 0.763696C34.1269 2.15146 37.2728 5.29743 38.6606 9.30113C39.4243 11.5039 39.4243 14.2395 39.4243 19.7136C39.4243 25.1877 39.4243 27.9233 38.6606 30.1261C37.2728 34.1298 34.1269 37.2758 30.1232 38.6635C27.9204 39.4272 25.1848 39.4272 19.7107 39.4272C14.2366 39.4272 11.501 39.4272 9.2982 38.6635C5.2945 37.2758 2.14853 34.1298 0.760766 30.1261C-0.00292969 27.9233 -0.00292969 25.1877 -0.00292969 19.7136'
                    fill='#A7E92F'
                />
                <path
                    d='M14.0629 16.9808H10.9141C10.5017 16.9808 10.1675 17.3151 10.1675 17.7274V29.3937C10.1675 29.8061 10.5017 30.1403 10.9141 30.1403H14.0629C14.4752 30.1403 14.8095 29.8061 14.8095 29.3937V17.7274C14.8095 17.3151 14.4752 16.9808 14.0629 16.9808Z'
                    fill='black'
                />
                <path
                    d='M14.0629 9.26978H10.9141C10.5017 9.26978 10.1675 9.60404 10.1675 10.0164V13.0398C10.1675 13.4521 10.5017 13.7864 10.9141 13.7864H14.0629C14.4752 13.7864 14.8095 13.4521 14.8095 13.0398V10.0164C14.8095 9.60404 14.4752 9.26978 14.0629 9.26978Z'
                    fill='black'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M21.6258 25.6322H19.1153C18.6679 25.6322 18.3687 25.9315 18.3687 26.3788V29.3909C18.3687 29.8383 18.6679 30.1346 19.1153 30.1375L21.6258 30.1546C35.9365 30.1546 35.9365 9.26978 21.6258 9.26978L19.1153 9.28687C18.6679 9.28972 18.3687 9.58608 18.3687 10.0335V13.0455C18.3687 13.4929 18.6679 13.7921 19.1153 13.7921H21.6258C29.7215 13.7921 29.7215 25.6322 21.6258 25.6322Z'
                    fill='black'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M93.2678 5.27464C95.6814 5.27464 97.3 7.16963 96.844 9.49776H91.3842C91.5039 10.4837 92.3217 11.0821 93.2364 11.0793C94.0714 11.0793 94.761 10.7003 95.1314 9.93375L96.6816 10.7259C96.0604 11.9427 94.7524 12.7435 93.2678 12.7435C91.0365 12.7321 89.4863 11.0707 89.4863 9.00763C89.4863 6.94451 91.0508 5.28604 93.2678 5.27179M95.0887 8.24393C94.9091 7.48879 94.0913 6.93311 93.2621 6.93311C92.4328 6.93311 91.6406 7.46314 91.4355 8.24393H95.0887Z'
                    fill='black'
                />
                <path
                    d='M58.289 6.20648L58.2348 5.47697H56.5308V12.5412H58.3659V8.90791C58.3659 6.7422 61.358 6.57692 61.358 8.43202V12.5412H63.1932V8.26674C63.1932 5.10083 59.7024 4.71328 58.2833 6.20648'
                    fill='black'
                />
                <path
                    d='M67.9063 10.7944H66.4929V5.29745H67.9063C69.5249 5.29745 70.6675 6.42875 70.6675 8.04733C70.6675 9.66591 69.5249 10.7972 67.9063 10.7972V10.7944ZM67.9063 3.55064H64.6577V12.5412H67.9063C70.5251 12.5412 72.5027 10.6148 72.5027 8.04448C72.5027 5.47413 70.5251 3.54779 67.9063 3.54779V3.55064Z'
                    fill='black'
                />
                <path
                    d='M75.5063 6.24351L75.4521 5.47696H73.748V12.5411H75.5832V9.47212C75.5832 7.73955 76.9026 6.99296 78.3587 7.31496V5.42852C77.4725 5.20625 76.1674 5.34303 75.5034 6.24636'
                    fill='black'
                />
                <path
                    d='M87.5598 5.47699L85.8244 10.5493L84.1004 5.47699H82.1484L84.7302 12.5412H86.9272L89.5118 5.47699H87.5598Z'
                    fill='black'
                />
                <path d='M55.1114 5.47699H53.2734V12.5412H55.1114V5.47699Z' fill='black' />
                <path d='M55.1114 2.74133H53.2734V4.57933H55.1114V2.74133Z' fill='black' />
                <path d='M81.197 5.47699H79.3618V12.5412H81.197V5.47699Z' fill='black' />
                <path d='M81.1998 2.74133H79.3618V4.57933H81.1998V2.74133Z' fill='black' />
                <path
                    d='M64.361 34.2894V25.9315L61.7622 34.2894H58.6989L56.1257 25.9828V34.2894H53.2817V22.2327H57.7186L60.2918 30.81L62.865 22.2327H67.2021V34.2894H64.3582H64.361Z'
                    fill='black'
                />
                <path
                    d='M76.4177 34.2894C76.3208 33.9958 76.2467 33.5798 76.221 33.2122C75.4602 34.0443 74.1864 34.6085 72.5707 34.6085C69.8265 34.6085 68.5527 33.2606 68.5527 31.3001C68.5527 27.8207 70.8809 27.4275 74.0924 26.9858C75.6853 26.7664 76.127 26.4472 76.127 25.6123C76.127 24.8286 75.3434 24.3613 74.0924 24.3613C72.6476 24.3613 72.0578 25.0709 71.9124 26.1509H68.946C68.9944 23.6518 70.3679 21.9106 74.2406 21.9106C78.1132 21.9106 79.387 23.6261 79.387 26.6638V34.2865H76.4205L76.4177 34.2894ZM76.1982 28.3821C75.8791 28.7013 75.3149 28.8722 74.1408 29.0917C72.3285 29.4336 71.7386 29.9494 71.7386 30.9781C71.7386 31.8843 72.2772 32.326 73.2831 32.326C74.9245 32.326 76.1498 31.1263 76.1754 29.7043L76.2011 28.3821H76.1982Z'
                    fill='black'
                />
                <path
                    d='M91.8312 29.9266C91.4893 32.7192 89.2096 34.6085 86.2916 34.6085C83.0088 34.6085 80.752 32.4286 80.752 28.1143C80.752 23.8 83.006 21.9135 86.3885 21.9135C89.5259 21.9135 91.6574 23.7031 91.854 26.5441H88.6425C88.4716 25.2932 87.5654 24.558 86.3628 24.558C85.015 24.558 83.9122 25.4157 83.9122 28.0373C83.9122 30.659 85.015 31.9327 86.266 31.9327C87.5169 31.9327 88.4972 31.2232 88.6197 29.9238H91.8312V29.9266Z'
                    fill='black'
                />
                <path
                    d='M96.7811 34.2894V24.7802H92.6406V22.2327H104.011V24.7802H99.8929V34.2894H96.7811Z'
                    fill='black'
                />
                <path
                    d='M107.564 29.0945C107.661 30.9069 108.693 32.0838 110.26 32.0838C111.289 32.0838 112.195 31.568 112.417 30.7359H115.7C114.965 33.2122 112.978 34.6085 110.405 34.6085C106.387 34.6085 104.375 32.4029 104.375 28.0402C104.375 24.3157 106.507 21.9135 110.209 21.9135C113.91 21.9135 115.871 24.3157 115.871 29.0945H107.564ZM112.588 27.0856C112.54 25.296 111.363 24.4383 110.112 24.4383C108.861 24.4383 107.784 25.4185 107.661 27.0856H112.588Z'
                    fill='black'
                />
                <path
                    d='M117.147 40V22.2327H120.211L120.236 23.7031C120.997 22.4777 122.123 21.9135 123.593 21.9135C126.631 21.9135 128.814 24.3157 128.814 28.3821C128.814 32.0325 126.976 34.6057 123.864 34.6057C122.368 34.6057 121.194 33.993 120.285 32.7192V39.9972H117.147V40ZM125.577 28.1627C125.577 26.0056 124.448 24.5124 122.855 24.5124C121.262 24.5124 120.16 25.9343 120.16 27.9689C120.16 30.5906 121.066 31.89 122.83 31.89C124.693 31.89 125.574 30.639 125.574 28.1656L125.577 28.1627Z'
                    fill='black'
                />
                <path
                    d='M137.684 34.2894C137.587 33.9958 137.513 33.5798 137.488 33.2122C136.727 34.0443 135.453 34.6085 133.837 34.6085C131.093 34.6085 129.819 33.2606 129.819 31.3001C129.819 27.8207 132.147 27.4275 135.359 26.9858C136.952 26.7664 137.394 26.4472 137.394 25.6123C137.394 24.8286 136.61 24.3613 135.359 24.3613C133.914 24.3613 133.324 25.0709 133.179 26.1509H130.213C130.261 23.6518 131.635 21.9106 135.507 21.9106C139.38 21.9106 140.654 23.6261 140.654 26.6638V34.2865H137.687L137.684 34.2894ZM137.465 28.3821C137.146 28.7013 136.581 28.8722 135.407 29.0917C133.595 29.4336 133.005 29.9494 133.005 30.9781C133.005 31.8843 133.544 32.326 134.55 32.326C136.191 32.326 137.416 31.1263 137.442 29.7043L137.468 28.3821H137.465Z'
                    fill='black'
                />
            </g>
            <defs>
                <clipPath id='clip0_5511_20345'>
                    <rect width='140.648' height='40' fill='white' />
                </clipPath>
            </defs>
        </svg>
    );
};
