import { IProps } from './props';

export const LogoLatAm = ({ height, isMobile }: IProps) => {
    return (
        <svg
            width={isMobile ? 117 : 146}
            height={height}
            viewBox='0 0 147 40'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'>
            <g clipPath='url(#clip0_5511_20283)'>
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M94.6506 5.35091C97.0991 5.35091 98.741 7.2733 98.2785 9.63508H92.7397C92.8611 10.6353 93.6908 11.2424 94.6187 11.2395C95.4658 11.2395 96.1653 10.855 96.5411 10.0774L98.1137 10.881C97.4835 12.1154 96.1567 12.9277 94.6506 12.9277C92.3871 12.9161 90.8145 11.2308 90.8145 9.13786C90.8145 7.04492 92.4015 5.36248 94.6506 5.34802M96.4978 8.36313C96.3156 7.59706 95.486 7.03336 94.6448 7.03336C93.8035 7.03336 92.9999 7.57105 92.7918 8.36313H96.4978Z'
                    fill='black'
                />
                <path
                    d='M59.1635 6.29619L59.1086 5.55614H57.3799V12.7224H59.2416V9.03667C59.2416 6.83966 62.2769 6.67199 62.2769 8.5539V12.7224H64.1386V8.38624C64.1386 5.17456 60.5973 4.78141 59.1577 6.29619'
                    fill='black'
                />
                <path
                    d='M68.9195 10.9504H67.4857V5.37407H68.9195C70.5615 5.37407 71.7207 6.52172 71.7207 8.16369C71.7207 9.80567 70.5615 10.9533 68.9195 10.9533V10.9504ZM68.9195 3.60201H65.624V12.7225H68.9195C71.5762 12.7225 73.5824 10.7683 73.5824 8.1608C73.5824 5.5533 71.5762 3.59912 68.9195 3.59912V3.60201Z'
                    fill='black'
                />
                <path
                    d='M76.6293 6.33385L76.5744 5.55622H74.8457V12.7225H76.7074V9.60913C76.7074 7.85152 78.0458 7.09413 79.523 7.42079V5.50708C78.624 5.2816 77.3 5.42036 76.6264 6.33674'
                    fill='black'
                />
                <path
                    d='M88.8569 5.55615L87.0964 10.7047L85.3503 5.55615H83.3701L85.9892 12.7224H88.218L90.8371 5.55615H88.8569Z'
                    fill='black'
                />
                <path d='M55.9427 5.55615H54.0781V12.7224H55.9427V5.55615Z' fill='black' />
                <path d='M55.9427 2.78101H54.0781V4.64557H55.9427V2.78101Z' fill='black' />
                <path d='M82.4046 5.55615H80.543V12.7224H82.4046V5.55615Z' fill='black' />
                <path d='M82.4075 2.78101H80.543V4.64557H82.4075V2.78101Z' fill='black' />
                <path
                    d='M61.4266 26.2572C61.2532 25.0633 60.6056 24.6412 59.14 24.6412C57.923 24.6412 57.2263 24.939 57.2263 25.6617C57.2263 26.3844 57.8969 26.6821 59.2151 27.0551C60.6085 27.454 61.9238 27.7257 62.8691 28.0986C64.1613 28.6219 64.884 29.466 64.884 31.0559C64.884 33.5912 63.0194 35.1088 59.5389 35.1088C55.7838 35.1088 53.6966 33.3425 53.6475 30.8565H56.9777C56.9777 32.0012 57.9461 32.6719 59.5129 32.6719C60.6576 32.6719 61.6752 32.325 61.6752 31.4028C61.6752 30.5327 60.7559 30.2581 59.7124 30.0095C57.6483 29.5122 56.5065 29.1653 55.6363 28.6161C54.4916 27.8963 54.0955 26.951 54.0955 25.8554C54.0955 23.7913 55.512 22.2274 59.2412 22.2274C62.7708 22.2274 64.3608 23.6208 64.5371 26.2543H61.4295L61.4266 26.2572Z'
                    fill='black'
                />
                <path
                    d='M69.1596 29.515C69.2579 31.3536 70.3044 32.5475 71.8943 32.5475C72.9379 32.5475 73.8572 32.0243 74.0826 31.1801H77.4128C76.667 33.6922 74.6521 35.1087 72.0417 35.1087C67.9657 35.1087 65.9248 32.8713 65.9248 28.4454C65.9248 24.6672 68.0871 22.2302 71.8423 22.2302C75.5974 22.2302 77.5863 24.6672 77.5863 29.515H69.1596ZM74.2561 27.477C74.2069 25.6616 73.013 24.7915 71.744 24.7915C70.4749 24.7915 69.3822 25.7859 69.2579 27.477H74.2561Z'
                    fill='black'
                />
                <path
                    d='M78.8057 34.785V22.554H81.8381V24.0196C82.9077 22.3777 84.1508 22.2302 85.469 22.2302H85.891V25.5373C85.5933 25.4882 85.2955 25.4621 84.9949 25.4621C83.006 25.4621 82.0376 26.4566 82.0376 28.4194V34.785H78.8057Z'
                    fill='black'
                />
                <path
                    d='M91.1843 34.7851L86.8076 22.5541H90.4124L92.9737 30.6339L95.5581 22.5541H98.9634L94.5636 34.7851H91.1814H91.1843Z'
                    fill='black'
                />
                <path
                    d='M99.9375 21.0855V18.0762H103.169V21.0855H99.9375ZM99.9375 34.785V22.554H103.169V34.785H99.9375Z'
                    fill='black'
                />
                <path
                    d='M115.672 30.3592C115.325 33.1921 113.013 35.1087 110.052 35.1087C106.722 35.1087 104.433 32.8973 104.433 28.5206C104.433 24.1439 106.719 22.2302 110.151 22.2302C113.333 22.2302 115.496 24.0456 115.695 26.9278H112.437C112.264 25.6587 111.345 24.9129 110.125 24.9129C108.757 24.9129 107.639 25.783 107.639 28.4426C107.639 31.1021 108.757 32.3943 110.026 32.3943C111.295 32.3943 112.29 31.6745 112.414 30.3563H115.672V30.3592Z'
                    fill='black'
                />
                <path
                    d='M116.965 21.0855V18.0762H120.197V21.0855H116.965ZM116.965 34.785V22.554H120.197V34.785H116.965Z'
                    fill='black'
                />
                <path
                    d='M121.541 28.668C121.541 24.7886 123.952 22.2302 127.831 22.2302C131.711 22.2302 134.047 24.7655 134.047 28.668C134.047 32.5706 131.636 35.1058 127.831 35.1058C124.027 35.1058 121.541 32.4694 121.541 28.668ZM130.789 28.668C130.789 26.1819 129.794 24.9649 127.831 24.9649C125.869 24.9649 124.874 26.1819 124.874 28.668C124.874 31.1541 125.869 32.3972 127.831 32.3972C129.794 32.3972 130.789 31.1541 130.789 28.668Z'
                    fill='black'
                />
                <path
                    d='M142.598 26.2572C142.424 25.0633 141.777 24.6412 140.311 24.6412C139.094 24.6412 138.397 24.939 138.397 25.6617C138.397 26.3844 139.068 26.6821 140.386 27.0551C141.779 27.454 143.095 27.7257 144.04 28.0986C145.332 28.6219 146.055 29.466 146.055 31.0559C146.055 33.5912 144.19 35.1088 140.71 35.1088C136.955 35.1088 134.868 33.3425 134.818 30.8565H138.149C138.149 32.0012 139.117 32.6719 140.684 32.6719C141.829 32.6719 142.846 32.325 142.846 31.4028C142.846 30.5327 141.927 30.2581 140.883 30.0095C138.819 29.5122 137.677 29.1653 136.807 28.6161C135.662 27.8963 135.266 26.951 135.266 25.8554C135.266 23.7913 136.683 22.2274 140.412 22.2274C143.942 22.2274 145.535 23.6208 145.708 26.2543H142.6L142.598 26.2572Z'
                    fill='black'
                />
                <path
                    d='M-0.382851 19.9986C-0.382851 14.4445 -0.382851 11.669 0.391995 9.43404C1.80002 5.37477 4.99192 2.18287 9.05408 0.774846C11.289 0 14.0646 0 19.6157 0C25.1668 0 27.9453 0 30.1802 0.774846C34.2424 2.18287 37.4343 5.37477 38.8423 9.43693C39.6171 11.6718 39.6171 14.4474 39.6171 20.0014C39.6171 25.5555 39.6171 28.331 38.8423 30.566C37.4343 34.6281 34.2424 37.82 30.1802 39.228C27.9453 40.0029 25.1697 40.0029 19.6157 40.0029C14.0617 40.0029 11.2861 40.0029 9.05119 39.228C4.98903 37.82 1.79713 34.6281 0.389104 30.566C-0.385742 28.331 -0.385742 25.5555 -0.385742 20.0014'
                    fill='#A7E92F'
                />
                <path
                    d='M13.8849 17.2288H10.6901C10.2718 17.2288 9.93262 17.5679 9.93262 17.9863V29.8229C9.93262 30.2413 10.2718 30.5804 10.6901 30.5804H13.8849C14.3033 30.5804 14.6424 30.2413 14.6424 29.8229V17.9863C14.6424 17.5679 14.3033 17.2288 13.8849 17.2288Z'
                    fill='black'
                />
                <path
                    d='M13.8849 9.40515H10.6901C10.2718 9.40515 9.93262 9.7443 9.93262 10.1627V13.2302C9.93262 13.6486 10.2718 13.9877 10.6901 13.9877H13.8849C14.3033 13.9877 14.6424 13.6486 14.6424 13.2302V10.1627C14.6424 9.7443 14.3033 9.40515 13.8849 9.40515Z'
                    fill='black'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M21.5586 26.0065H19.0114C18.5575 26.0065 18.2539 26.3101 18.2539 26.764V29.82C18.2539 30.274 18.5575 30.5746 19.0114 30.5775L21.5586 30.5949C36.0783 30.5949 36.0783 9.40515 21.5586 9.40515L19.0114 9.4225C18.5575 9.42539 18.2539 9.72608 18.2539 10.18V13.236C18.2539 13.6899 18.5575 13.9935 19.0114 13.9935H21.5586C29.7725 13.9935 29.7725 26.0065 21.5586 26.0065Z'
                    fill='black'
                />
            </g>
            <defs>
                <clipPath id='clip0_5511_20283'>
                    <rect width='146.052' height='40' fill='white' />
                </clipPath>
            </defs>
        </svg>
    );
};
