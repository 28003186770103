import { Button, IButtonProps } from '@indriver/nova';
import { useBoundActions } from '../../model/bound-actions';

export const ChooseTaskerCtaButton = ({ onClick, ...otherProps }: IButtonProps) => {
    const { showModal } = useBoundActions();
    return (
        <Button
            {...otherProps}
            onClick={(e) => {
                onClick?.(e);
                showModal('button');
            }}
        />
    );
};
