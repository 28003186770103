import { useMutation, useQueryClient } from '@tanstack/react-query';
import { postAcceptBid, postSetBidContacted } from 'common/api/bid';
import { toastError } from 'common/ui';

interface IUseAcceptBidParams {
    onSettled: () => void;
    cityId: number;
}

export const useAcceptBid = ({ onSettled, cityId }: IUseAcceptBidParams) => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (bidId: string) => postAcceptBid(bidId, cityId),
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: ['getOrder'] });
        },
        onError: () => {
            toastError({ message: 'Unexpected error', toastId: 'cancel-order-error' });
        },
        onSettled,
    });
};

interface IUseSetBidContactedParams {
    cityId: number;
}

export const useSetBidContacted = ({ cityId }: IUseSetBidContactedParams) => {
    return useMutation(async (bidId: string) => postSetBidContacted(bidId, cityId));
};
