import { IOffersResponse } from '.';
import { superMastersApi } from './base';

export const getOffers = async (orderId: string) => {
    const response = await superMastersApi.get<IOffersResponse>(`order/${orderId}/offers`);
    return response.data;
};

export const sendOffer = async (orderId: string, taskerId: number) => {
    const response = await superMastersApi.post(`order/${orderId}/offer`, { tasker_id: taskerId });
    return response.data;
};

export const getCategoryOffers = async (catalogId: number) => {
    const response = await superMastersApi.get<IOffersResponse>(`public/offers/${catalogId}`);
    return response.data.collection;
};
