export function generatePath<Path extends string>(
    originalPath: Path,
    params: {
        [key: string]: string | null;
    } = {},
): string {
    let path: string = originalPath;

    if (path.endsWith('*') && path !== '*' && !path.endsWith('/*')) {
        path = path.replace(/\*$/, '/*') as Path;
    }

    // ensure `/` is added at the beginning if the path is absolute
    const prefix = path.startsWith('/') ? '/' : '';

    const stringify = (p: unknown) => (p === null || p === undefined ? '' : typeof p === 'string' ? p : String(p));

    const segments = path
        .split(/\/+/)
        .map((segment, index, array) => {
            const isLastSegment = index === array.length - 1;

            // only apply the splat if it's the last segment
            if (isLastSegment && segment === '*') {
                const star = '*';
                // Apply the splat
                return stringify(params[star]);
            }

            const keyMatch = /^:([\w-]+)(\??)$/.exec(segment);
            if (keyMatch) {
                const [, key, optional] = keyMatch;
                const param = params[key] ?? null;

                if (optional !== '?' && param === null) {
                    throw new Error(`Missing ":${key}" param`);
                }

                return stringify(param);
            }

            // Remove any optional markers from optional static segments
            return segment.replace(/\?$/g, '');
        })
        // Remove empty segments
        .filter((segment) => !!segment);

    return prefix + segments.join('/');
}
