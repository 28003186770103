import { useQuery } from '@tanstack/react-query';
import type { Optional } from 'utility-types';
import { getSubServicesByCatalogId, IGetSubServicesByCatalogIdParams } from 'common/api/service';

export type IUseGetSubServicesQueryParams = Optional<IGetSubServicesByCatalogIdParams, 'catalogId'>;

export const getGetSubServicesKey = (params: IUseGetSubServicesQueryParams) => ['getSubServices', params];

export const getGetSubServicesQueryOptions = ({ locale, catalogId }: IUseGetSubServicesQueryParams) => ({
    queryKey: getGetSubServicesKey({ locale, catalogId }),
    queryFn: async () => {
        if (catalogId === undefined) {
            throw new Error('Parameter <catalogId> is not provided');
        }

        return getSubServicesByCatalogId({ locale, catalogId });
    },
});

export const useGetSubServicesQuery = (params: IUseGetSubServicesQueryParams) =>
    useQuery({
        ...getGetSubServicesQueryOptions(params),
        enabled: params.catalogId !== undefined,
    });
