import { superMastersApi } from './base';
import { IBidsResponse } from './models';

export const getBids = async (orderId: string, cityId: number) => {
    const response = await superMastersApi.get<IBidsResponse>('bids', {
        params: {
            orderID: orderId,
        },
        headers: {
            'X-City-Id': cityId,
        },
    });
    return response.data;
};

export const postAcceptBid = async (bidId: string, cityId: number) => {
    const response = await superMastersApi.post<{ id: string }>(`bid/${bidId}/accept`, undefined, {
        headers: {
            'X-City-Id': cityId,
        },
    });
    return response.data;
};

export const postSetBidContacted = async (bidId: string, cityId: number) => {
    const response = await superMastersApi.post<{ id: string }>(`bid/${bidId}/contacted`, undefined, {
        headers: {
            'X-City-Id': cityId,
        },
    });
    return response.data;
};
