import { useTranslation } from 'next-i18next';
import { ChevronRightSmall } from '@indriver/mireska';
import { RoundIcon, Text } from '@indriver/nova';
import { variables } from '@indriver/yrel';
import { MiniCell } from 'common/ui';
import { UserAvatar } from 'entities/user';
import { IMixedModelTasker } from '../../model/types';
import * as Styled from './picked-tasker-card.styles';

interface IProps {
    tasker: IMixedModelTasker;
    onClick?: () => void;
}

export const PickedTaskerCard = ({ tasker, onClick }: IProps) => {
    const { t } = useTranslation('common');

    return (
        <MiniCell
            prefix={<UserAvatar className={Styled.avatar} size='m' user={tasker} />}
            content={
                <>
                    <Text size='s' weight='bold' style={{ marginBottom: variables['spacers-3xs'] }}>
                        {t('features.mixed_model.picked_tasker.title', { tasker_first_name: tasker.first_name })}
                    </Text>
                    <Text size='s' color='rgba(0,0,0,.5)'>
                        {t('features.mixed_model.picked_tasker.subtitle')}
                    </Text>
                </>
            }
            suffix={<RoundIcon design='neutral-inverse' size='s' content={<ChevronRightSmall size={24} />} />}
            onClick={onClick}
        />
    );
};
