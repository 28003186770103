import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict';
import { Avatar, RatingBar } from '@indriver/nova';
import { IReview, IWizardReviewUser } from 'common/api';
import { formatName } from 'common/lib';
import { useDateFnsLocale } from 'common/lib/date-fns-i18n';
import * as Styled from './common-review.styles';

interface ICommonReviewProps {
    author: IWizardReviewUser;
    review: Pick<IReview, 'created_at' | 'message' | 'rating'>;
}

export const CommonReview = ({ review, author }: ICommonReviewProps) => {
    const locale = useDateFnsLocale();
    return (
        <Styled.Container>
            <Styled.Rating>
                <RatingBar size='s' value={review.rating} />
            </Styled.Rating>
            <Styled.Message>{review.message}</Styled.Message>
            <Styled.Footer>
                {formatName(author)}
                {', '}
                {formatDistanceToNowStrict(new Date(review.created_at), { locale, addSuffix: true })}
                <Styled.Avatar>
                    <Avatar design='neutral-inverse' image={author.avatar} content={author.first_name} />
                </Styled.Avatar>
            </Styled.Footer>
        </Styled.Container>
    );
};
