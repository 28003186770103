import { useTranslation } from 'next-i18next';
import React, { ChangeEvent, MouseEvent, useState } from 'react';
import { Button, Group, RatingBar, Text } from '@indriver/nova';
import { ILocation } from 'common/api';
import { formatPrice, recordEventShort, useBreakpoints } from 'common/lib';
import { AvatarWithBadge, InlineItem, Rating } from 'common/ui';
import { useAnalyticsPayload } from 'entities/analytics';
import { usePageCity } from 'entities/city';
import { ITasker } from 'entities/tasker';
import { TaskerReferences } from 'widgets/tasker-references';
import { renderTaskerDetails } from '../lib/render-tasker-details';
import * as Styled from './bid-card.styles';

interface IProps {
    bidId: string;
    price: number;
    currency: string;
    bidLocation?: ILocation | undefined;
    orderLocation?: ILocation | undefined;
    tasker: ITasker;
    comment?: string;
    callTaskerButton: React.ReactNode;
    whatsappTaskerButton: React.ReactNode;
    shouldShowChooseButton?: boolean;
    chooseTaskerButton?: React.ReactNode;
    onRatingChange?: (e: ChangeEvent<HTMLInputElement> | MouseEvent<HTMLInputElement>) => void;
    shouldShowRating?: boolean;
    confirmValue?: number | undefined;
}

// eslint-disable-next-line complexity
const BidCardMobile = ({
    bidId,
    price,
    currency,
    bidLocation,
    orderLocation,
    tasker,
    comment,
    callTaskerButton,
    whatsappTaskerButton,
    shouldShowChooseButton = true,
    chooseTaskerButton,
    onRatingChange,
    shouldShowRating,
    confirmValue,
}: IProps) => {
    const [shouldShowText, setShouldShowText] = useState(false);
    const { t } = useTranslation(['common', 'active_order']);
    const currentCity = usePageCity();

    return (
        <Styled.MobileCard>
            <Styled.MobileHeader>
                <Group gap={4} direction='column'>
                    <Text size='xl' weight='bold'>
                        {formatPrice(price, currency)}
                    </Text>
                    <Text size='m' weight='bold'>
                        {tasker.name}
                    </Text>
                </Group>
                <AvatarWithBadge
                    avatarSize='xxl'
                    badgeSize='m'
                    content={tasker.name}
                    iconSize={16}
                    image={tasker.avatarUrl}
                    rating={tasker.rating}
                />
            </Styled.MobileHeader>
            <Styled.MobileContent>
                <Text size='s' color='var(--text-and-icon-secondary)'>
                    {renderTaskerDetails(tasker, bidLocation, orderLocation, currentCity.countryIso)}
                </Text>
                {tasker.description && (
                    <>
                        <Styled.DescriptionText
                            size='s'
                            color='var(--text-and-icon-secondary)'
                            show={shouldShowText}
                            onClick={() => setShouldShowText(!shouldShowText)}>
                            {tasker.description}
                        </Styled.DescriptionText>
                        {shouldShowText && (
                            <Styled.ShowText size='s' onClick={() => setShouldShowText(false)}>
                                {t('active_order:bid_tasker_should_show_less')}
                            </Styled.ShowText>
                        )}
                    </>
                )}
                {comment && (
                    <Styled.MobileComment>
                        <Text size='s'>“{comment}</Text>
                    </Styled.MobileComment>
                )}
            </Styled.MobileContent>
            <TaskerReferences tasker={tasker} bidId={bidId} />
            <Styled.MobileButtons>
                {shouldShowChooseButton && chooseTaskerButton}
                {callTaskerButton}
                {whatsappTaskerButton}
            </Styled.MobileButtons>
            {shouldShowRating && (
                <>
                    <Styled.DividerCard />
                    {!confirmValue ? (
                        <Styled.RateContainer>
                            <Text size='l' weight='bold'>
                                {t('active_order:masters_review_bidscard_title')}
                            </Text>
                            <Rating name='rating' onChange={onRatingChange} onClick={onRatingChange} />
                        </Styled.RateContainer>
                    ) : (
                        <RatingBar value={confirmValue} size='m' />
                    )}
                </>
            )}
        </Styled.MobileCard>
    );
};

// eslint-disable-next-line complexity
const BidCardDesktop = ({
    bidId,
    price,
    currency,
    bidLocation,
    orderLocation,
    tasker,
    comment,
    callTaskerButton,
    whatsappTaskerButton,
    shouldShowChooseButton = true,
    chooseTaskerButton,
    onRatingChange,
    shouldShowRating,
    confirmValue,
}: IProps) => {
    const { t } = useTranslation('active_order');
    const [areContactsButtonsVisible, setAreContactsButtonsVisible] = React.useState(false);
    const [shouldShowText, setShouldShowText] = useState(false);
    const currentCity = usePageCity();
    const defaultAnalyticsPayload = useAnalyticsPayload();
    const handleContactsButtonsVisibility = () => {
        setAreContactsButtonsVisible((value) => !value);
        recordEventShort('masters_customer_reveal_contacts_click', {
            ...defaultAnalyticsPayload,
            bid_id: bidId,
        });
    };

    return (
        <Styled.DesktopCard>
            <Styled.LeftWrapper>
                <AvatarWithBadge
                    avatarSize='xxxl'
                    badgeSize='m'
                    content={tasker.name}
                    iconSize={16}
                    image={tasker.avatarUrl}
                    rating={tasker.rating}
                />
            </Styled.LeftWrapper>
            <Styled.RightWrapper verticalCenter={!comment && !tasker.description}>
                <Styled.Header>
                    <Group gap={4} direction='column'>
                        <Styled.DesktopPriceAndName>
                            <Text size='xl' weight='bold'>
                                <InlineItem>{formatPrice(price, currency)}</InlineItem>
                                <InlineItem>{tasker.name}</InlineItem>
                            </Text>
                        </Styled.DesktopPriceAndName>
                        <Text size='ml' color='var(--text-and-icon-secondary)'>
                            {renderTaskerDetails(tasker, bidLocation, orderLocation, currentCity.countryIso)}
                        </Text>
                        {tasker.description && (
                            <>
                                <Styled.DescriptionText
                                    size='ml'
                                    color='var(--text-and-icon-secondary)'
                                    show={shouldShowText}
                                    onClick={() => setShouldShowText(!shouldShowText)}>
                                    {tasker.description}
                                </Styled.DescriptionText>
                                {shouldShowText && (
                                    <Styled.ShowText size='ml' onClick={() => setShouldShowText(false)}>
                                        {t('active_order:bid_tasker_should_show_less')}
                                    </Styled.ShowText>
                                )}
                            </>
                        )}
                    </Group>
                    <Styled.DesktopButtons verticalCenter={!comment && !tasker.description}>
                        {areContactsButtonsVisible || !shouldShowChooseButton ? (
                            <>
                                {callTaskerButton}
                                {whatsappTaskerButton}
                            </>
                        ) : (
                            <Button size='m' onClick={handleContactsButtonsVisibility}>
                                {t('active_order:bid_see_contacts_button')}
                            </Button>
                        )}
                        {shouldShowChooseButton && chooseTaskerButton}
                    </Styled.DesktopButtons>
                </Styled.Header>
                {comment && (
                    <Styled.DesktopComment>
                        <Text size='ml'>“{comment}</Text>
                    </Styled.DesktopComment>
                )}
                <TaskerReferences tasker={tasker} bidId={bidId} />
                {shouldShowRating && (
                    <>
                        <Styled.DividerCard />
                        {!confirmValue ? (
                            <Styled.RateContainer>
                                <Text size='l' weight='bold'>
                                    {t('active_order:masters_review_bidscard_title')}
                                </Text>
                                <Rating name='rating' onChange={onRatingChange} onClick={onRatingChange} />
                            </Styled.RateContainer>
                        ) : (
                            <RatingBar value={confirmValue} size='m' />
                        )}
                    </>
                )}
            </Styled.RightWrapper>
        </Styled.DesktopCard>
    );
};

export const BidCard = (props: IProps) => {
    const media = useBreakpoints();
    return media.isGtS ? <BidCardDesktop {...props} /> : <BidCardMobile {...props} />;
};
