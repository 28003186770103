import { ICityInfo } from '../../model/types';
import { CityButton } from '../city-button';
import * as Styled from './cities-list.styles';

interface IProps {
    cities: ICityInfo[];
    onCityClick: (city: ICityInfo) => void;
}

export const CitiesList = ({ cities, onCityClick }: IProps) => (
    <Styled.Cities>
        {cities.map((city) => (
            <Styled.City key={`${city.countryName}/${city.name}`}>
                <CityButton city={city} onClick={() => onCityClick(city)} />
            </Styled.City>
        ))}
    </Styled.Cities>
);
