import { IProps } from './props';

export const LogoDefault = ({ height, isMobile }: IProps) => {
    return (
        <svg
            width={isMobile ? 112 : 140}
            height={height}
            viewBox='0 0 141 40'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'>
            <g clipPath='url(#clip0_5511_20034)'>
                <path
                    d='M-3.84699e-05 19.9986C-3.84699e-05 14.4445 -3.84725e-05 11.669 0.774808 9.43404C2.18283 5.37477 5.37473 2.18287 9.4369 0.774846C11.6718 0 14.4474 0 19.9985 0C25.5497 0 28.3281 0 30.563 0.774846C34.6252 2.18287 37.8171 5.37477 39.2251 9.43693C40 11.6718 40 14.4474 40 20.0014C40 25.5555 40 28.331 39.2251 30.566C37.8171 34.6281 34.6252 37.82 30.563 39.228C28.3281 40.0029 25.5525 40.0029 19.9985 40.0029C14.4445 40.0029 11.6689 40.0029 9.434 39.228C5.37184 37.82 2.17994 34.6281 0.771917 30.566C-0.00292969 28.331 -0.00292969 25.5555 -0.00292969 20.0014'
                    fill='#A7E92F'
                />
                <path
                    d='M14.2681 17.2288H11.0733C10.6549 17.2288 10.3158 17.5679 10.3158 17.9863V29.8229C10.3158 30.2413 10.6549 30.5804 11.0733 30.5804H14.2681C14.6864 30.5804 15.0256 30.2413 15.0256 29.8229V17.9863C15.0256 17.5679 14.6864 17.2288 14.2681 17.2288Z'
                    fill='black'
                />
                <path
                    d='M14.2681 9.40515H11.0733C10.6549 9.40515 10.3158 9.7443 10.3158 10.1627V13.2302C10.3158 13.6486 10.6549 13.9877 11.0733 13.9877H14.2681C14.6864 13.9877 15.0256 13.6486 15.0256 13.2302V10.1627C15.0256 9.7443 14.6864 9.40515 14.2681 9.40515Z'
                    fill='black'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M21.9414 26.0065H19.3942C18.9403 26.0065 18.6367 26.3101 18.6367 26.764V29.82C18.6367 30.274 18.9403 30.5746 19.3942 30.5775L21.9414 30.5949C36.4611 30.5949 36.4611 9.40515 21.9414 9.40515L19.3942 9.4225C18.9403 9.42539 18.6367 9.72608 18.6367 10.18V13.236C18.6367 13.6899 18.9403 13.9935 19.3942 13.9935H21.9414C30.1553 13.9935 30.1553 26.0065 21.9414 26.0065Z'
                    fill='black'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M94.6295 5.35165C97.0784 5.35165 98.7206 7.27431 98.258 9.63643H92.7184C92.8398 10.6368 93.6696 11.2439 94.5977 11.2411C95.4448 11.2411 96.1445 10.8565 96.5203 10.0788L98.0932 10.8825C97.4629 12.1171 96.1358 12.9295 94.6295 12.9295C92.3657 12.918 90.7928 11.2324 90.7928 9.13914C90.7928 7.0459 92.3801 5.36321 94.6295 5.34875M96.477 8.3643C96.2948 7.59812 95.4651 7.03433 94.6237 7.03433C93.7824 7.03433 92.9786 7.5721 92.7704 8.3643H96.477Z'
                    fill='black'
                />
                <path
                    d='M59.1398 6.2971L59.0849 5.55695H57.356V12.7243H59.2179V9.03798C59.2179 6.84065 62.2537 6.67296 62.2537 8.55515V12.7243H64.1156V8.38745C64.1156 5.17531 60.5739 4.78211 59.1341 6.2971'
                    fill='black'
                />
                <path
                    d='M68.8977 10.952H67.4636V5.37482H68.8977C70.5399 5.37482 71.6993 6.52263 71.6993 8.16484C71.6993 9.80705 70.5399 10.9549 68.8977 10.9549V10.952ZM68.8977 3.6025H65.6017V12.7243H68.8977C71.5547 12.7243 73.5612 10.7698 73.5612 8.16195C73.5612 5.55407 71.5547 3.59961 68.8977 3.59961V3.6025Z'
                    fill='black'
                />
                <path
                    d='M76.6085 6.33465L76.5536 5.55691H74.8246V12.7242H76.6866V9.6104C76.6866 7.85254 78.0252 7.09504 79.5026 7.42175V5.50776C78.6035 5.28225 77.2793 5.42103 76.6056 6.33754'
                    fill='black'
                />
                <path
                    d='M88.8384 5.55695L87.0776 10.7033L85.3284 5.55695H83.348L85.9674 12.7243H88.1965L90.8189 5.55695H88.8384Z'
                    fill='black'
                />
                <path d='M55.9161 5.55695H54.0513V12.7243H55.9161V5.55695Z' fill='black' />
                <path d='M55.9161 2.78137H54.0513V4.64621H55.9161V2.78137Z' fill='black' />
                <path d='M82.3823 5.55695H80.5204V12.7243H82.3823V5.55695Z' fill='black' />
                <path d='M82.3852 2.78137H80.5204V4.64621H82.3852V2.78137Z' fill='black' />
                <path
                    d='M61.4036 26.261C61.2301 25.0669 60.5825 24.6448 59.1167 24.6448C57.8995 24.6448 57.2027 24.9426 57.2027 25.6654C57.2027 26.3882 57.8734 26.686 59.1918 27.0589C60.5854 27.4579 61.9009 27.7297 62.8463 28.1027C64.1387 28.626 64.8615 29.4702 64.8615 31.0604C64.8615 33.596 62.9967 35.1139 59.5157 35.1139C55.76 35.1139 53.6725 33.3473 53.6234 30.8609H56.954C56.954 32.0058 57.9226 32.6766 59.4896 32.6766C60.6346 32.6766 61.6523 32.3296 61.6523 31.4073C61.6523 30.5371 60.7329 30.2624 59.6891 30.0138C57.6248 29.5165 56.4828 29.1695 55.6125 28.6202C54.4676 27.9003 54.0715 26.9549 54.0715 25.8591C54.0715 23.7948 55.4882 22.2277 59.2179 22.2277C62.748 22.2277 64.3382 23.6213 64.5146 26.2552H61.4065L61.4036 26.261Z'
                    fill='black'
                />
                <path
                    d='M69.2591 29.5165C69.3574 31.3553 70.404 32.5493 71.9942 32.5493C73.0379 32.5493 73.9573 32.026 74.1828 31.1818H77.5135C76.7676 33.6943 74.7524 35.111 72.1416 35.111C68.065 35.111 66.0238 32.8732 66.0238 28.4467C66.0238 24.6679 68.1864 22.2306 71.9421 22.2306C75.6978 22.2306 77.687 24.6679 77.687 29.5165H69.2591ZM74.3563 27.4782C74.3071 25.6625 73.1131 24.7922 71.8438 24.7922C70.5746 24.7922 69.4817 25.7868 69.3574 27.4782H74.3563Z'
                    fill='black'
                />
                <path
                    d='M78.8579 34.7871V22.5544H81.8908V24.0203C82.9605 22.378 84.2038 22.2306 85.5222 22.2306H85.9443V25.5381C85.6465 25.489 85.3487 25.463 85.048 25.463C83.0589 25.463 82.0903 26.4576 82.0903 28.4207V34.7871H78.8579Z'
                    fill='black'
                />
                <path
                    d='M91.001 34.7871L86.6237 22.5544H90.2291L92.7907 30.6353L95.3754 22.5544H98.7813L94.3809 34.7871H90.9981H91.001Z'
                    fill='black'
                />
                <path
                    d='M99.7296 21.0886V18.0788H102.962V21.0886H99.7296ZM99.7296 34.79V22.5573H102.962V34.79H99.7296Z'
                    fill='black'
                />
                <path
                    d='M115.44 30.3636C115.094 33.197 112.781 35.1138 109.82 35.1138C106.489 35.1138 104.199 32.9021 104.199 28.5247C104.199 24.1474 106.486 22.2335 109.918 22.2335C113.101 22.2335 115.264 24.0491 115.464 26.9317H112.205C112.032 25.6624 111.112 24.9165 109.892 24.9165C108.525 24.9165 107.406 25.7868 107.406 28.4467C107.406 31.1066 108.525 32.399 109.794 32.399C111.063 32.399 112.058 31.6791 112.182 30.3607H115.44V30.3636Z'
                    fill='black'
                />
                <path
                    d='M119.818 29.5165C119.916 31.3553 120.963 32.5493 122.553 32.5493C123.597 32.5493 124.516 32.026 124.742 31.1818H128.072C127.326 33.6943 125.311 35.111 122.7 35.111C118.624 35.111 116.583 32.8732 116.583 28.4467C116.583 24.6679 118.745 22.2306 122.501 22.2306C126.257 22.2306 128.246 24.6679 128.246 29.5165H119.818ZM124.915 27.4782C124.866 25.6625 123.672 24.7922 122.403 24.7922C121.133 24.7922 120.04 25.7868 119.916 27.4782H124.915Z'
                    fill='black'
                />
                <path
                    d='M136.752 26.261C136.578 25.0669 135.931 24.6448 134.465 24.6448C133.247 24.6448 132.551 24.9426 132.551 25.6654C132.551 26.3882 133.221 26.686 134.54 27.0589C135.933 27.4579 137.249 27.7297 138.194 28.1027C139.487 28.626 140.21 29.4702 140.21 31.0604C140.21 33.596 138.345 35.1139 134.864 35.1139C131.108 35.1139 129.021 33.3473 128.971 30.8609H132.302C132.302 32.0058 133.271 32.6766 134.838 32.6766C135.983 32.6766 137 32.3296 137 31.4073C137 30.5371 136.081 30.2624 135.037 30.0138C132.973 29.5165 131.831 29.1695 130.961 28.6202C129.816 27.9003 129.42 26.9549 129.42 25.8591C129.42 23.7948 130.836 22.2277 134.566 22.2277C138.096 22.2277 139.689 23.6213 139.863 26.2552H136.755L136.752 26.261Z'
                    fill='black'
                />
            </g>
            <defs>
                <clipPath id='clip0_5511_20034'>
                    <rect width='140.207' height='40' fill='white' />
                </clipPath>
            </defs>
        </svg>
    );
};
