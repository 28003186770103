import { useMutation, useQueryClient } from '@tanstack/react-query';
import { sendOffer } from 'common/api/offer';
import { useSelectedTasker } from './use-selected-tasker';

interface ISendOfferMutationParams {
    taskerId: number;
    orderId: string;
}

export const useSendOffer = () => {
    const queryClient = useQueryClient();
    const [, setSelectedTasker] = useSelectedTasker();
    return useMutation({
        mutationFn: async ({ taskerId, orderId }: ISendOfferMutationParams) => sendOffer(orderId, taskerId),
        onSuccess: async (_, { orderId }) => {
            setSelectedTasker(null);
            await queryClient.invalidateQueries({ queryKey: ['getOffers', { orderId }] });
        },
    });
};
