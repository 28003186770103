import { captureException } from '@sentry/minimal';
import axios from 'axios';
import { isSSR } from 'common/lib';

const { PORT = '3000' } = process.env;

const getServerHttpClientConfig = () => ({
    baseURL: `http://127.0.0.1:${PORT}/api`,
    timeout: 3000,
});

const getBrowserHttpClientConfig = () => ({
    baseURL: '/api',
});

export const localPublicApi = axios.create(isSSR ? getServerHttpClientConfig() : getBrowserHttpClientConfig());

localPublicApi.interceptors.response.use(null, (error) => {
    captureException(error);
    throw error;
});
