import { useTranslation } from 'next-i18next';
import { useCallback } from 'react';
import { IOffer } from 'common/api';
import { useGetCatalogTaskersCountQuery } from 'entities/catalog';
import { useBoundActions } from '../../model/bound-actions';
import { useSelectedTasker } from '../../model/use-selected-tasker';
import { PickTaskerCta } from '../pick-tasker-cta';
import { PickedTaskerCard } from '../picked-tasker-card';
import * as Styled from './mixed-model-banner.styles';

interface IProps {
    cityId: number;
    offers: IOffer[];
    onModalShow?: () => void;
}

export const MixedModelBanner = ({ cityId, offers, onModalShow }: IProps) => {
    const { i18n } = useTranslation();

    const { showModal } = useBoundActions();

    const handleShowModal = useCallback(() => {
        onModalShow?.();
        showModal('banner');
    }, [onModalShow, showModal]);

    const [mixedModelTasker] = useSelectedTasker();
    const { data: taskerCount = 0 } = useGetCatalogTaskersCountQuery({ locale: i18n.resolvedLanguage, cityId });

    if (offers.length < 3) {
        return null;
    }

    if (mixedModelTasker) {
        return (
            <Styled.Wrapper>
                <PickedTaskerCard tasker={mixedModelTasker} onClick={handleShowModal} />
            </Styled.Wrapper>
        );
    }

    if (taskerCount > 0) {
        return (
            <Styled.Wrapper>
                <PickTaskerCta taskerCount={taskerCount} onClick={handleShowModal} />
            </Styled.Wrapper>
        );
    }

    return null;
};
