import { recordEvent } from 'common/lib';
import { useAnalyticsPayload } from 'entities/analytics';
import { ITasker, TaskerReferences as TaskerEntityReferences } from 'entities/tasker';
import * as Styled from './tasker-references.styles';

interface IProps {
    tasker: ITasker;
    bidId?: string;
    defaultVisible?: boolean;
}

export const TaskerReferences = ({ tasker, bidId, defaultVisible = false }: IProps) => {
    const defaultAnalyticsPayload = useAnalyticsPayload();

    const handleGallerySlideClick = () => {
        recordEvent({
            actionGA: 'masters_customer_bid_see_reviews_click',
            actionAmplitude: 'masters_customer_bid_see_reviews_click',
            payload: { ...defaultAnalyticsPayload, bid_id: bidId },
        });
    };

    const handleGalleryModalOpen = () => {
        recordEvent({
            actionGA: 'masters_customer_bid_see_reviews_click',
            actionAmplitude: 'masters_customer_bid_see_reviews_click',
            actionAF: 'masters.customer.bid_see_revievs.click',
            payload: { ...defaultAnalyticsPayload, bid_id: bidId },
        });
    };

    const handleReviewsModalOpen = () => {
        recordEvent({
            actionGA: 'masters_customer_see_portfolio_click',
            actionAmplitude: 'masters_customer_see_portfolio_click',
            actionAF: 'masters.customer.see_portfolio.click',
            payload: { ...defaultAnalyticsPayload, bid_id: bidId },
        });
    };

    const handleReviewCardClick = () => {
        recordEvent({
            actionGA: 'masters_customer_see_portfolio_click',
            actionAmplitude: 'masters_customer_see_portfolio_click',
            actionAF: 'masters.customer.see_portfolio.click',
            payload: { ...defaultAnalyticsPayload, bid_id: bidId },
        });
    };

    return (
        <Styled.ReferencesWrapper>
            <TaskerEntityReferences
                tasker={tasker}
                defaultVisible={defaultVisible}
                onGallerySlideClick={handleGallerySlideClick}
                onGalleryModalOpen={handleGalleryModalOpen}
                onReviewsModalOpen={handleReviewsModalOpen}
                onReviewCardClick={handleReviewCardClick}
            />
        </Styled.ReferencesWrapper>
    );
};
