import React from 'react';
import ReactFocusLock from 'react-focus-lock';
import { RemoveScroll } from 'react-remove-scroll';
import { Portal } from 'common/ui';
import * as Styled from './mobile-drawer.styles';

interface IProps {
    isOpen?: boolean;
    children: React.ReactNode;
}

export const MobileDrawer = ({ isOpen, children }: IProps) => (
    <Portal>
        <RemoveScroll enabled={isOpen}>
            <ReactFocusLock disabled={!isOpen}>
                <Styled.Box isOpen={isOpen}>{children}</Styled.Box>
            </ReactFocusLock>
        </RemoveScroll>
    </Portal>
);
