import axios from 'axios';
import getConfig from 'next/config';
import { createSignature } from 'common/lib';
import { ICheckAuthCodeResponse, IRequestAuthCodeResponse } from './models';

const { publicRuntimeConfig } = getConfig();
const PRIVATE_JWK = publicRuntimeConfig?.privateJWK ?? '';

export const postRequestAuthCode = async (data: string) => {
    const response = await axios.post<IRequestAuthCodeResponse>('/api/request-auth-code', data, {
        headers: {
            'content-type': 'text/plain',
            'x-signature': await createSignature(PRIVATE_JWK, JSON.parse(data).encodedPhoneData),
        },
    });
    return response.data;
};

export const postCheckAuthCode = async (data: string) => {
    const response = await axios.post<ICheckAuthCodeResponse>('/api/check-auth-code', data, {
        headers: {
            'content-type': 'text/plain',
            'x-signature': await createSignature(PRIVATE_JWK, JSON.parse(data).encodedPhoneData),
        },
    });
    return response.data;
};
