import { getCrypto } from './get-crypto';

const ALGORITHM = { name: 'RSASSA-PKCS1-v1_5', hash: 'SHA-256' };

export const createSignature = async (privateJwk: JsonWebKey, data: string): Promise<string> => {
    const { subtle } = await getCrypto();
    const privateKey = await subtle.importKey('jwk', privateJwk, ALGORITHM, true, ['sign']);
    const signature = await subtle.sign(ALGORITHM, privateKey, new TextEncoder().encode(data));
    return Buffer.from(signature).toString('base64');
};

export const verifySignature = async (publicJwk: JsonWebKey, signature: string, data: string): Promise<boolean> => {
    const { subtle } = await getCrypto();
    const publicKey = await subtle.importKey('jwk', publicJwk, ALGORITHM, true, ['verify']);
    return subtle.verify(ALGORITHM, publicKey, Buffer.from(signature, 'base64'), new TextEncoder().encode(data));
};
