import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { getBids } from 'common/api/bid';
import { getStream } from 'common/api/stream';

interface IUseGetBidsParams {
    orderId: string;
    cityId: number;
}

export const useGetBids = ({ cityId, orderId }: IUseGetBidsParams) =>
    useQuery({
        queryKey: ['getBids', { orderId }],
        queryFn: async () => getBids(orderId, cityId),
        enabled: false,
    });

export const useGetStream = (enabled: boolean) => {
    const [lastId, setLastId] = useState(0);
    return useQuery({
        // TODO: won't there be any caching problems?
        queryKey: ['getStream'],
        queryFn: async () => {
            const streamData = await getStream(lastId);
            setLastId(streamData.last_id);
            return streamData;
        },
        refetchInterval: 2000,
        enabled,
    });
};
