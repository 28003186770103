import { IProps } from './props';

export const LogoKz = ({ height, isMobile }: IProps) => {
    return (
        <svg
            width={isMobile ? 128 : 160}
            height={height}
            viewBox='0 0 160 40'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            preserveAspectRatio='none'>
            <g clipPath='url(#clip0_5511_20409)'>
                <path
                    d='M-7.9873e-05 19.7122C-7.9873e-05 14.2377 -7.98756e-05 11.5019 0.76367 9.29895C2.15153 5.29781 5.29772 2.15161 9.30171 0.76375C11.5046 0 14.2404 0 19.7121 0C25.1837 0 27.9224 0 30.1253 0.76375C34.1293 2.15161 37.2755 5.29781 38.6634 9.3018C39.4271 11.5047 39.4271 14.2405 39.4271 19.715C39.4271 25.1895 39.4271 27.9253 38.6634 30.1282C37.2755 34.1322 34.1293 37.2784 30.1253 38.6663C27.9224 39.43 25.1866 39.43 19.7121 39.43C14.2376 39.43 11.5018 39.43 9.29887 38.6663C5.29488 37.2784 2.14868 34.1322 0.760821 30.1282C-0.00292969 27.9253 -0.00292969 25.1895 -0.00292969 19.715'
                    fill='#A7E92F'
                />
                <path
                    d='M14.0637 16.9821H10.9146C10.5023 16.9821 10.168 17.3163 10.168 17.7287V29.3958C10.168 29.8082 10.5023 30.1425 10.9146 30.1425H14.0637C14.476 30.1425 14.8103 29.8082 14.8103 29.3958V17.7287C14.8103 17.3163 14.476 16.9821 14.0637 16.9821Z'
                    fill='black'
                />
                <path
                    d='M14.0637 9.27045H10.9146C10.5023 9.27045 10.168 9.60473 10.168 10.0171V13.0408C10.168 13.4531 10.5023 13.7874 10.9146 13.7874H14.0637C14.476 13.7874 14.8103 13.4531 14.8103 13.0408V10.0171C14.8103 9.60473 14.476 9.27045 14.0637 9.27045Z'
                    fill='black'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M21.627 25.6341H19.1163C18.6689 25.6341 18.3696 25.9333 18.3696 26.3807V29.393C18.3696 29.8404 18.6689 30.1368 19.1163 30.1396L21.627 30.1567C35.9387 30.1567 35.9387 9.27045 21.627 9.27045L19.1163 9.28755C18.6689 9.2904 18.3696 9.58678 18.3696 10.0342V13.0465C18.3696 13.4939 18.6689 13.7931 19.1163 13.7931H21.627C29.7233 13.7931 29.7233 25.6341 21.627 25.6341Z'
                    fill='black'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M93.2744 5.27501C95.6882 5.27501 97.3069 7.17013 96.8509 9.49843H91.3907C91.5103 10.4845 92.3282 11.0829 93.243 11.0801C94.078 11.0801 94.7677 10.7011 95.1382 9.93445L96.6885 10.7267C96.0672 11.9436 94.7591 12.7444 93.2744 12.7444C91.043 12.733 89.4927 11.0715 89.4927 9.00826C89.4927 6.945 91.0572 5.2864 93.2744 5.27216M95.0954 8.24451C94.9159 7.48931 94.098 6.9336 93.2687 6.9336C92.4394 6.9336 91.6471 7.46366 91.4419 8.24451H95.0954Z'
                    fill='black'
                />
                <path
                    d='M58.293 6.2069L58.2389 5.47735H56.5347V12.542H58.3699V8.90853C58.3699 6.74267 61.3623 6.57738 61.3623 8.43261V12.542H63.1975V8.26732C63.1975 5.10117 59.7065 4.7136 58.2873 6.2069'
                    fill='black'
                />
                <path
                    d='M67.9109 10.7951H66.4974V5.29782H67.9109C69.5296 5.29782 70.6724 6.4292 70.6724 8.04789C70.6724 9.66659 69.5296 10.798 67.9109 10.798V10.7951ZM67.9109 3.55088H64.6621V12.542H67.9109C70.5299 12.542 72.5076 10.6156 72.5076 8.04504C72.5076 5.47451 70.5299 3.54803 67.9109 3.54803V3.55088Z'
                    fill='black'
                />
                <path
                    d='M75.5118 6.24394L75.4576 5.47734H73.7534V12.542H75.5887V9.47278C75.5887 7.74009 76.9082 6.99344 78.3644 7.31547V5.42889C77.4781 5.20661 76.1729 5.3434 75.5089 6.24679'
                    fill='black'
                />
                <path
                    d='M87.5661 5.47736L85.8306 10.55L84.1064 5.47736H82.1543L84.7362 12.542H86.9334L89.5182 5.47736H87.5661Z'
                    fill='black'
                />
                <path d='M55.1155 5.47736H53.2773V12.542H55.1155V5.47736Z' fill='black' />
                <path d='M55.1155 2.74152H53.2773V4.57965H55.1155V2.74152Z' fill='black' />
                <path d='M81.2025 5.47736H79.3672V12.542H81.2025V5.47736Z' fill='black' />
                <path d='M81.2053 2.74152H79.3672V4.57965H81.2053V2.74152Z' fill='black' />
                <path
                    d='M53.3428 34.2889V22.2314H56.4548V31.7412H60.2792V22.2314H63.2943V31.7412H67.1188V22.2314H70.2308V34.2889H53.3456H53.3428Z'
                    fill='black'
                />
                <path
                    d='M74.6909 29.0937C74.7878 30.9062 75.8194 32.0832 77.3868 32.0832C78.4156 32.0832 79.3218 31.5673 79.5441 30.7352H82.8271C82.0919 33.2117 80.1055 34.6081 77.5322 34.6081C73.5139 34.6081 71.502 32.4023 71.502 28.0393C71.502 24.3146 73.6336 21.9122 77.3355 21.9122C81.0374 21.9122 82.9981 24.3146 82.9981 29.0937H74.6909ZM79.7151 27.0846C79.6667 25.2949 78.4897 24.4371 77.2386 24.4371C75.9876 24.4371 74.9103 25.4174 74.7878 27.0846H79.7151Z'
                    fill='black'
                />
                <path
                    d='M84.1265 27.9168V25.882C84.1265 20 85.7195 17.8683 89.8375 17.8683H91.2111C92.314 17.8683 93.0236 17.6489 93.2459 16.1271H95.1324C94.8617 18.6264 93.4169 20.416 91.0401 20.416H89.4727C87.683 20.416 86.8737 21.9606 86.8737 24.1664C87.8284 22.77 89.5212 22.3539 90.966 22.3539C94.3972 22.3539 96.4063 24.6822 96.4063 28.31C96.4063 32.2314 94.0524 34.6081 90.2792 34.6081C86.5061 34.6081 84.1265 32.0832 84.1265 27.9168ZM93.2687 28.2616C93.2687 25.7851 92.2399 24.5597 90.2536 24.5597C88.2672 24.5597 87.2157 25.7851 87.2157 28.2844C87.2157 30.7837 88.2216 32.0091 90.2308 32.0091C92.2399 32.0091 93.2687 30.7352 93.2687 28.2587V28.2616Z'
                    fill='black'
                />
                <path
                    d='M100.692 29.0937C100.789 30.9062 101.821 32.0832 103.388 32.0832C104.417 32.0832 105.323 31.5673 105.546 30.7352H108.829C108.093 33.2117 106.107 34.6081 103.534 34.6081C99.5154 34.6081 97.5034 32.4023 97.5034 28.0393C97.5034 24.3146 99.6351 21.9122 103.337 21.9122C107.039 21.9122 109 24.3146 109 29.0937H100.692ZM105.717 27.0846C105.668 25.2949 104.491 24.4371 103.24 24.4371C101.989 24.4371 100.912 25.4174 100.789 27.0846H105.717Z'
                    fill='black'
                />
                <path
                    d='M110.225 39.9999V22.2314H113.289L113.314 23.7019C114.075 22.4764 115.201 21.9122 116.671 21.9122C119.709 21.9122 121.892 24.3146 121.892 28.3812C121.892 32.0319 120.054 34.6052 116.942 34.6052C115.446 34.6052 114.272 33.9925 113.363 32.7187V39.9971H110.225V39.9999ZM118.655 28.1618C118.655 26.0045 117.526 24.5112 115.933 24.5112C114.34 24.5112 113.237 25.9333 113.237 27.968C113.237 30.5899 114.144 31.8894 115.908 31.8894C117.771 31.8894 118.652 30.6383 118.652 28.1647L118.655 28.1618Z'
                    fill='black'
                />
                <path
                    d='M130.96 34.2889V24.7791H127.651L127.529 26.9364C127.161 33.2345 125.984 34.2889 122.553 34.2889H122.063V31.7412H122.382C124.146 31.7412 124.833 31.2026 125.104 26.7654L125.374 22.2314H134.075V34.2889H130.963H130.96Z'
                    fill='black'
                />
                <path
                    d='M138.484 29.0937C138.581 30.9062 139.612 32.0832 141.18 32.0832C142.209 32.0832 143.115 31.5673 143.337 30.7352H146.62C145.885 33.2117 143.899 34.6081 141.325 34.6081C137.307 34.6081 135.295 32.4023 135.295 28.0393C135.295 24.3146 137.427 21.9122 141.128 21.9122C144.83 21.9122 146.791 24.3146 146.791 29.0937H138.484ZM143.508 27.0846C143.46 25.2949 142.283 24.4371 141.032 24.4371C139.781 24.4371 138.703 25.4174 138.581 27.0846H143.508Z'
                    fill='black'
                />
                <path
                    d='M148.02 39.9999V22.2314H151.083L151.109 23.7019C151.87 22.4764 152.995 21.9122 154.466 21.9122C157.504 21.9122 159.687 24.3146 159.687 28.3812C159.687 32.0319 157.849 34.6052 154.737 34.6052C153.24 34.6052 152.066 33.9925 151.157 32.7187V39.9971H148.02V39.9999ZM156.449 28.1618C156.449 26.0045 155.321 24.5112 153.728 24.5112C152.135 24.5112 151.032 25.9333 151.032 27.968C151.032 30.5899 151.938 31.8894 153.702 31.8894C155.566 31.8894 156.446 30.6383 156.446 28.1647L156.449 28.1618Z'
                    fill='black'
                />
            </g>
            <defs>
                <clipPath id='clip0_5511_20409'>
                    <rect width='159.684' height='40' fill='white' />
                </clipPath>
            </defs>
        </svg>
    );
};
